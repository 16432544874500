import { Select, Divider } from 'antd';
import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MKSelectProps } from './mk-select.types';
import _debounce from 'lodash/debounce';
import { useGetTechMapsQuery } from '@/shared/state/api/swagger';
import { NotFoundContentDropdown } from '@/shared';
import { Button, Flex, useDisclosure } from '@chakra-ui-kraud/react';
import { TechCardFormModal } from '../tech-card-form';

export const MKSelect: FC<MKSelectProps> = ({
	className,
	product,
	productNameStrict,
	productSymbolStrict,
	onSelect,
	name,
	...props
}) => {
	const { control } = useFormContext();
	const [numberQuery, setNumberQuery] = useState(null);
	const { data, options, isFetching } = useGetTechMapsQuery(
		{
			number: numberQuery ?? undefined,
			statuses: ['accepted_at_sgd', 'progress', 'partially_issued'],
			productNameStrict,
			productSymbolStrict,
		},
		{
			selectFromResult: (result) => {
				return {
					...result,
					options: result?.data?.payload?.map((item) => ({
						value: item.number,
						label: item.number,
					})),
				};
			},
		},
	);
	const debouncedMapSearch = _debounce((search) => setNumberQuery(search), 700);
	const { isOpen, onClose, onOpen } = useDisclosure();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, value }, fieldState: { error } }) => {
				return (
					<>
						<Select
							{...props}
							status={error ? 'error' : ''}
							tabIndex={1}
							showSearch
							placeholder="Поиск..."
							optionFilterProp="children"
							onChange={(e) => {
								onChange(e);
							}}
							style={{
								minWidth: '227px',
								width: '100%',
								borderRadius: 0,
							}}
							filterOption={() => true}
							onSearch={(search) => {
								const number = Number(search);
								if (!Number.isNaN(number)) {
									debouncedMapSearch(search);
								}
							}}
							onSelect={(item) => {
								const selected = data?.payload?.find((el) => el.number === item);
								onSelect(selected!);
							}}
							value={value}
							options={options}
							className={className}
							notFoundContent={
								<NotFoundContentDropdown
									isFetchingNaming={isFetching}
									alertName={'Введите обозначение'}
								/>
							}
							dropdownRender={(menu) => (
								<>
									{menu}
									<Divider style={{ margin: '0', marginTop: '4px' }} />
									<Flex justifyContent={'center'} px={1} pt={2}>
										<Button size="sm" variant="ghost" onClick={onOpen} width="100%">
											Создать связь с новой МК
										</Button>
									</Flex>
								</>
							)}
						/>
						{isOpen && (
							<TechCardFormModal
								onCreate={onSelect}
								product={product}
								isOpen={isOpen}
								onClose={onClose}
							/>
						)}
					</>
				);
			}}
		/>
	);
};
