import { CommentProps } from './comment.types';
import { FC, useState } from 'react';
import { Flex, Stack, chakra, IconButton, Portal, Textarea, Button, useToast } from '@chakra-ui-kraud/react';
import { abbreviateUser } from '@/shared/core';
import dayjs from 'dayjs';
import { MadIcon } from 'madsoft-icons';
import { DeleteModal } from '../delete-modal';
import { useChangeCommentTextMutation, useDeleteCommentMutation } from '@/shared/state/api/swagger';
import { showErrorToast, showSuccessToast } from '../toasts';

export const Comment: FC<CommentProps> = ({
	text,
	productCommentId,
	created_at,
	author,
	ableEdit,
	productTechMapId,
	...divProps
}) => {
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [editedText, setEditedText] = useState(text);

	const [deleteComment, { isLoading: deleteCommentLoading }] = useDeleteCommentMutation();
	const [changeComment, { isLoading: changeCommentLoading }] = useChangeCommentTextMutation();

	const toast = useToast();

	const handleSave = () => {
		if (!productTechMapId || !editedText) return;
		if (editedText !== text) {
			changeComment({
				productCommentId,
				productTechMapId,
				productTechMapCommentUpdateTextRequest: { text: editedText },
			})
				.then(() => showSuccessToast(toast, { title: 'Успех', description: 'Комментарий измененен' }))
				.catch(() =>
					showErrorToast(toast, {
						title: 'Ошибка',
						description: 'Произошла ошибка при изменении комментария',
					}),
				)
				.finally(() => setIsEditing(false));
		} else {
			setIsEditing(false);
		}
	};

	const handleCancel = () => {
		setIsEditing(false);
		setEditedText(text);
	};

	return (
		<>
			<Stack
				p={4}
				overflowWrap="break-word"
				position="relative"
				{...divProps}
				_hover={{
					cursor: ableEdit ? 'pointer' : undefined,
					'& > [data-tools="true"]': {
						opacity: 1,
					},
				}}
			>
				{isEditing ? (
					<>
						<Textarea
							value={editedText}
							onChange={(e) => setEditedText(e.target.value)}
							placeholder="Введите комментарий"
							size="sm"
						/>
						<Flex gap={2} mt={2} justifyContent="end">
							<Button colorScheme="tertiary" variant="outline" size="sm" onClick={handleCancel}>
								Отмена
							</Button>
							<Button
								colorScheme="primary"
								variant="solid"
								size="sm"
								isDisabled={!editedText}
								onClick={handleSave}
								isLoading={changeCommentLoading}
							>
								Сохранить
							</Button>
						</Flex>
					</>
				) : (
					<>
						<chakra.p whiteSpace="pre-line">{text}</chakra.p>
						<Flex gap={3}>
							<chakra.p color="gray.500">
								{author &&
									abbreviateUser(
										author.first_name ?? '',
										author.last_name ?? '',
										author.middle_name ?? '',
									)}
							</chakra.p>
							<chakra.p color="gray.500">{dayjs(created_at).format('DD.MM.YYYY')}</chakra.p>
						</Flex>
					</>
				)}

				{ableEdit && !isEditing && (
					<Flex
						marginTop="0 !important"
						position="absolute"
						top="0"
						paddingLeft="100px"
						right="0"
						height="100%"
						alignItems="center"
						background="linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%)"
						opacity={0}
						gap={1}
						transition="opacity 0.3s"
						data-tools
					>
						<IconButton
							size="sm"
							aria-label="Редактировать"
							icon={
								<MadIcon
									type="outline"
									mode="default"
									size="default"
									module="basic"
									name="pencil-square"
								/>
							}
							variant="outline"
							colorScheme="tertiary"
							onClick={() => setIsEditing(true)}
						/>
						<IconButton
							size="sm"
							aria-label="Удалить"
							icon={
								<MadIcon type="outline" mode="default" size="default" module="basic" name="trashcan" />
							}
							variant="outline"
							colorScheme="danger"
							onClick={() => setDeleteModalOpen(true)}
						/>
					</Flex>
				)}
			</Stack>

			<Portal>
				<DeleteModal
					title="Удаление комментария"
					text="Вы уверены, что хотите удалить комментарий?"
					isOpen={deleteModalOpen}
					onClose={() => setDeleteModalOpen(false)}
					deleteRequestLoading={deleteCommentLoading}
					onDelete={() =>
						deleteComment({ productCommentId, productTechMapId: Number(productTechMapId) })
							.then(() => showSuccessToast(toast, { title: 'Успех', description: 'Комментарий удален' }))
							.catch(
								() => () =>
									showSuccessToast(toast, {
										title: 'Ошибка',
										description: 'При удалении комментария произошла ошибка',
									}),
							)
							.finally(() => setDeleteModalOpen(false))
					}
				/>
			</Portal>
		</>
	);
};
