import type { FC } from 'react';
import type { OperationsBlockTypes } from '../model/operations.types';

import classnames from 'classnames';
import { clsx } from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import { matchPath } from 'react-router-dom';
import dayjs from 'dayjs';
import { chakra, IconButton, Input, Table, Td, Text, Th, Thead, Tr } from '@chakra-ui-kraud/react';
import { DatePicker, Select } from 'antd';
import { MadIcon } from 'madsoft-icons';

import { ProductOperationDomain, useGetDefaultOperationsQuery } from '@/shared/state/api/swagger';
import { TECH_CARD_NEW, useAppSelector, UserRoles, useTechCard } from '@/shared';

import defaultStyles from '../../../tech-card.module.scss';
import styles from './default-operations.module.scss';
import { FormT } from '@/pages/tech-card-page/formConfig';
import { convertNumberToNumberStringWithDot } from '@/shared/core/utils/convert-string-to-number-string';

export const DefaultOperationsBlock: FC<OperationsBlockTypes> = ({
	isEditable,
	focusedBlock,
	setFocusedBlock,
	employee,
	historedOperations,
	renderCancelButton,
	renderRenewOperationButton,
	setSideModalLinkOperation,
}) => {
	const { data: defaultOperations } = useGetDefaultOperationsQuery();
	const { cardInfo } = useTechCard();

	const userRole = useAppSelector((state) => state.auth.userProfile?.role);
	const { control, setValue, getValues } = useFormContext<FormT>();

	const { product_operations: fields } = getValues();
	const { default_product_operations: default_fields } = getValues();

	const isCreationMode = !!matchPath(TECH_CARD_NEW, location.pathname) && !cardInfo;

	const sortedOperationsTypes = fields ? [...fields].sort((a, b) => Number(a?.step_id) - Number(b?.step_id)) : [];

	// определение завершенной операции (если есть ФИО и дата) и пустого поля
	const isOperationDoneAndEmptyField = (
		operation: ProductOperationDomain,
		fieldValue: string | number | null | undefined,
	) => {
		if (!!operation.fio?.length && !!operation.date?.length && !fieldValue) {
			return true;
		}
		return false;
	};

	// определение завершенной операции (если есть ФИО и дата)
	const isOperationDone = (operation: ProductOperationDomain) => {
		if (!!operation.fio?.length && !!operation.date?.length) {
			return true;
		}
		return false;
	};

	return (
		<Table
			className={clsx(
				focusedBlock?.block === 'operations' &&
					focusedBlock.focusedBy === 'tab' &&
					!focusedBlock.atCurrentBlock &&
					defaultStyles.focused,
				isEditable && styles['edit-table'],
			)}
			onFocus={() => setFocusedBlock && setFocusedBlock('operations')}
		>
			<Thead display="table-header-group">
				{/* 1835 */}
				<Th style={{ verticalAlign: 'middle' }} w="460px">
					Наименование операции
				</Th>
				<Th style={{ verticalAlign: 'middle' }} w="300px">
					Дата
				</Th>
				<Th style={{ verticalAlign: 'middle' }} w="163px">
					Годных грамм
				</Th>
				<Th style={{ verticalAlign: 'middle' }} w="162px">
					Годных штук
				</Th>
				<Th style={{ verticalAlign: 'middle' }} w="350px">
					Фамилия исполнителя
				</Th>
				<Th style={{ verticalAlign: 'middle' }} w="400px">
					Подпись
				</Th>
			</Thead>

			{!isEditable && !isCreationMode && (
				<>
					{cardInfo?.default_product_operations?.map((operation) => (
						<Tr key={operation.id} className={clsx(operation.is_canceled ? styles['field-disabled'] : '')}>
							<Td style={{ textAlign: 'left' }}>
								{!operation.has_link ? (
									<Text>{`${operation.name} ${
										operation.is_canceled ? '— операция отменена' : ''
									}`}</Text>
								) : (
									<chakra.div
										display="flex"
										justifyContent="space-between"
										alignItems="center"
										paddingRight={1}
									>
										<chakra.div>
											{operation.name}
											{operation.id && <sup>{historedOperations[operation.id]}</sup>}
											{operation.is_canceled ? '— операция отменена' : ''}
										</chakra.div>
										<IconButton
											aria-label=""
											size="sx"
											variant="ghost"
											colorScheme="primary"
											onClick={() =>
												setSideModalLinkOperation({
													isOpen: true,
													title: `${operation.step_id} - ${operation.name}`,
													operationId: Number(operation.id),
												})
											}
											icon={
												<MadIcon
													module="basic"
													size="default"
													type="outline"
													mode="default"
													name="link"
												/>
											}
										/>
									</chakra.div>
								)}
							</Td>
							<Td>{dayjs(operation.date).isValid() ? dayjs(operation.date).format('DD.MM.YYYY') : ''}</Td>
							<Td>
								{!isOperationDoneAndEmptyField(operation, operation.count_out_gram)
									? operation.count_out_gram
									: '-'}
							</Td>
							<Td>
								{!isOperationDoneAndEmptyField(operation, operation.count_out_number)
									? operation.count_out_number
									: '-'}
							</Td>
							<Td>{operation.fio}</Td>
							<Td></Td>
						</Tr>
					))}
				</>
			)}
			{!isEditable && isCreationMode && sortedOperationsTypes?.length ? (
				<>
					{defaultOperations &&
						defaultOperations.map((operation) => (
							<Tr key={operation.id}>
								<Td style={{ textAlign: 'left' }}>{operation.name}</Td>
								<Td></Td>
								<Td></Td>
								<Td></Td>
								<Td></Td>
								<Td></Td>
							</Tr>
						))}
				</>
			) : (
				<></>
			)}

			{isEditable &&
				!isCreationMode &&
				default_fields?.map((field, index) => (
					<Tr key={field.id}>
						<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
							<chakra.div display="flex" justifyContent="space-between" alignItems="center" height="100%">
								<Text padding={isEditable ? '8px 1px 8px 8px' : ''} fontWeight="normal">
									{field.name}
								</Text>
								<Controller
									name={`default_product_operations.${index}.is_canceled`}
									control={control}
									render={() => {
										// если в операции нет даты или нет ФИО исполнителя или операция уже отменена
										if (!field.date || !field.fio || field.is_canceled) {
											if (field.is_canceled) {
												return <>{renderRenewOperationButton(field, 'default_operation')}</>;
											} else return <>{renderCancelButton(field, 'default_operation')}</>;
										} else return <></>;
									}}
								/>
							</chakra.div>
						</Td>
						<Td className={clsx(styles['date-style'], field.is_canceled && styles['field-disabled'])}>
							{!field.is_canceled ? (
								<Controller
									name={`default_product_operations.${index}.date`}
									control={control}
									render={({ field: { onChange }, fieldState: { error } }) => (
										<DatePicker
											style={{ width: '100%', padding: '0' }}
											tabIndex={7}
											status={error ? 'error' : ''}
											onChange={onChange}
											defaultValue={
												field.date && dayjs(field.date).isValid()
													? dayjs(field.date)
													: undefined
											}
											format={'DD.MM.YYYY'}
											name={`default_product_operations.${index}.date`}
										/>
									)}
								/>
							) : (
								<Text fontWeight="normal" textAlign="left">
									{field.date ? dayjs(field.date).format('DD.MM.YYYY') : ''}
								</Text>
							)}
						</Td>

						<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
							{!field.is_canceled && (!isOperationDone(field) || userRole === UserRoles.admin) ? (
								<Controller
									name={`default_product_operations.${index}.count_out_gram`}
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
										formState: { defaultValues },
									}) => (
										<Input
											backgroundColor="transparent"
											tabIndex={7}
											isInvalid={!!error}
											onChange={(e) => {
												onChange(e);

												if (
													index <
													(defaultValues?.default_product_operations?.length ?? 0) - 1
												) {
													// перенос значения из предпоследней дефолтной операции в последнюю кастомную
													// гр., поля Сдача на СГД -> раздел Кол-во, Консервация
													setValue(
														`product_operations.${
															sortedOperationsTypes.length - 1
														}.count_in_gram`,
														Number(convertNumberToNumberStringWithDot(e.target.value)),
													);
												}
											}}
											value={value}
											name={`default_product_operations.${index}.count_out_gram`}
											className={field.is_canceled ? styles['field-disabled'] : ''}
											disabled={field.is_canceled}
										/>
									)}
								/>
							) : (
								<Text
									p="6px"
									fontWeight="normal"
									textAlign={
										!isOperationDoneAndEmptyField(field, field.count_out_gram) ? 'left' : 'center'
									}
								>
									{!isOperationDoneAndEmptyField(field, field.count_out_gram)
										? field.count_out_gram
										: '-'}
								</Text>
							)}
						</Td>
						<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
							{!field.is_canceled ? (
								<Controller
									name={`default_product_operations.${index}.count_out_number`}
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
										formState: { defaultValues },
									}) => (
										<Input
											backgroundColor="transparent"
											tabIndex={7}
											isInvalid={!!error}
											onChange={(e) => {
												onChange(e);
												if (
													index <
													(defaultValues?.default_product_operations?.length ?? 0) - 1
												) {
													// перенос значения из предпоследней дефолтной операции в последнюю кастомную
													// шт., поля Сдача на СГД -> раздел Кол-во, Консервация
													setValue(
														`product_operations.${
															sortedOperationsTypes.length - 1
														}.count_in_number`,
														Number(convertNumberToNumberStringWithDot(e.target.value)),
													);
												}
												if (!e.target.value) {
													setValue(`default_product_operations.${index}.wasted`, undefined);
												} else {
													setValue(
														`default_product_operations.${index}.wasted`,
														Number(
															getValues(
																`default_product_operations.${index}.count_in_number`,
															),
														) - Number(e.target.value),
													);
												}
											}}
											value={value}
											name={`default_product_operations.${index}.count_out_number`}
											className={field.is_canceled ? styles['field-disabled'] : ''}
											disabled={field.is_canceled}
										/>
									)}
								/>
							) : (
								<Text
									p="6px"
									fontWeight="normal"
									textAlign={
										!isOperationDoneAndEmptyField(field, field.count_out_number) ? 'left' : 'center'
									}
								>
									{!isOperationDoneAndEmptyField(field, field.count_out_number)
										? field.count_out_number
										: '-'}
								</Text>
							)}
						</Td>
						<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
							{!field.is_canceled ? (
								<Controller
									name={`default_product_operations.${index}.fio`}
									control={control}
									render={({ field: { value, onChange } }) => (
										<Select
											allowClear
											tabIndex={7}
											filterOption={(input, option) =>
												(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
											}
											showSearch
											placeholder="Выберите ФИО"
											optionFilterProp="children"
											value={value}
											onChange={(e) => {
												onChange(e);
												setValue(
													`default_product_operations.${index}.fio`,
													e ? employee.find((el) => el.value === Number(e))?.label : '',
												);
											}}
											options={employee}
											style={{ width: '100%' }}
											className={classnames(styles['custom-select'])}
										/>
									)}
								/>
							) : (
								<Text p="6px" fontWeight="normal" textAlign="center">
									{field.fio}
								</Text>
							)}
						</Td>
						<Td></Td>
					</Tr>
				))}
		</Table>
	);
};
