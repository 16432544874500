import type { FC } from 'react';
import type { BlockProps } from './block.types';

import { Button, Flex, Text } from '@chakra-ui-kraud/react';

export const Block: FC<BlockProps> = ({ children, title, action, onAction }) => {
	return (
		<Flex direction="column" gap="16px" w="100%">
			<Flex direction="row" w="100%" justifyContent="space-between">
				<Text fontSize="md" lineHeight="6" fontWeight="medium" color="gray.1000" p="0" m="0">
					{title}
				</Text>
				<Button size="xs" variant="ghost" colorScheme="primary" onClick={onAction}>
					{action}
				</Button>
			</Flex>
			<Flex direction="column" gap="12px">
				{children}
			</Flex>
		</Flex>
	);
};
