import {
	EMPLOYEES,
	NUM_CATALOG,
	PRODUCT_CATALOG,
	TECH_CARDS_PATH,
	USERS,
	ISSUES_PATH,
	ASSEMBLY_PATH,
} from '@/shared/core';

export enum NavButtonNames {
	TECH_CARD = 'buttonTechCard',
	NUM_CATALOG = 'buttonNumCatalog',
	PRODUCT_CATALOG = 'buttonProductCatalog',
	USERS = 'buttonUsers',
	EMPLOYEES = 'employees',
	ISSUES = 'issues',
	ASSEMBLY = 'assembly',
}

export const activeButtonMatcher: { [path: string]: NavButtonNames } = {
	[TECH_CARDS_PATH]: NavButtonNames.TECH_CARD,
	[NUM_CATALOG]: NavButtonNames.NUM_CATALOG,
	[PRODUCT_CATALOG]: NavButtonNames.PRODUCT_CATALOG,
	[USERS]: NavButtonNames.USERS,
	[EMPLOYEES]: NavButtonNames.EMPLOYEES,
	[ISSUES_PATH]: NavButtonNames.ISSUES,
	[ASSEMBLY_PATH]: NavButtonNames.ASSEMBLY,
	['/']: NavButtonNames.TECH_CARD,
};
