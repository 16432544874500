import {
	showErrorToast,
	showSuccessToast,
	SideModal,
	StandardConsumptionTypeEnum,
	STANDARD_CONSUMPTION_TYPES,
	SubmitModal,
} from '@/shared';
import {
	ProductOperationRequestCreate,
	ProductTechMapRequestCreate,
	useCreateTechMapMutation,
	useGetProductsQuery,
} from '@/shared/state/api/swagger';
import { useToast } from '@chakra-ui-kraud/toast';
import { Button, ButtonGroup } from '@chakra-ui/button';
import { FC, useRef, useState } from 'react';
import { TechCardFormModalProps, FormT, FormHandle } from '../model/tech-card-form.types';
import { getTechCardName } from '@/shared/core/utils/tech-card-utils';
import { TechCardForm } from './tech-card-form';
import dayjs from 'dayjs';
import { convertNumberToNumberStringWithDot } from '@/shared/core/utils/convert-string-to-number-string';

export const TechCardFormModal: FC<TechCardFormModalProps> = ({ onCreate, onClose, isOpen, product }) => {
	const toast = useToast();
	const formRef = useRef<FormHandle>(null);

	const [create, { isLoading }] = useCreateTechMapMutation();
	const { selectedProduct, isFetching } = useGetProductsQuery(
		{
			nameStrict: product?.product_name,
			symbolStrict: product?.product_symbol,
		},
		{
			selectFromResult: (result) => ({
				...result,
				selectedProduct: result?.data?.payload?.[0],
			}),
		},
	);
	const [submitModal, setSubmitModal] = useState<null | {
		remainder: string;
		standardConsumptionType: StandardConsumptionTypeEnum;
	}>(null);

	const prepareRequest = (data: FormT): ProductTechMapRequestCreate => {
		const { name, extra_name, symbol, material, extra_symbol } = data.product || {};
		const { file } = data;

		return {
			symbol: data.symbol,
			product: {
				name: name ?? '',
				extra_name,
				symbol: symbol ?? '',
				extra_symbol,
				material: {
					symbol: material?.symbol,
					gost: material?.gost,
					sortament: material?.sortament,
					gost_na_sortament: material?.gost_na_sortament,
				},
				approval_card: data?.product?.approval_card ?? '',
				extra_approval_card: data?.product?.extra_approval_card ?? '',
			},
			created_at: data.created_at ? dayjs(data.created_at, 'DD.MM.YYYY', true).format() : dayjs().format(),
			product_operations:
				data?.product_operations?.map((operation: ProductOperationRequestCreate) => ({
					...operation,
				})) ?? [],
			num: {
				num: data?.num?.num || 0,
				symbol: data?.num?.symbol,
				gost: data?.num?.gost,
				sortament: data?.num?.sortament,
				gost_na_sortament: data?.num?.gost_na_sortament,
				number_of_melt: data?.num?.number_of_melt,
				date_of_manufacture: data?.num?.date_of_manufacture ? data?.num?.date_of_manufacture : undefined,
				o_v_or_date_of_manufacture: data?.num?.o_v_or_date_of_manufacture,
				o_v: data?.num?.o_v,
				replace_decision: data?.num?.replace_decision,
				standard_consumption_type: data?.num?.standard_consumption_type || 'old',
			},
			product_plan: {
				fio: data?.product_plan?.fio,
				issuance_count: data?.product_plan?.issuance_count
					? Number(String(data?.product_plan?.issuance_count).replace(',', '.'))
					: undefined,
				list_number: data?.product_plan?.list_number,
				count: Number(data?.product_plan?.count),
				NR_kg: data.product_plan.NR_kg
					? convertNumberToNumberStringWithDot(data.product_plan.NR_kg)
					: undefined,
				NR_meters: data.product_plan.NR_meters
					? convertNumberToNumberStringWithDot(data.product_plan.NR_meters)
					: undefined,
				NR_square_meters: data.product_plan.NR_square_meters
					? convertNumberToNumberStringWithDot(data.product_plan.NR_square_meters)
					: undefined,
				NR_grams: data.product_plan.NR_grams
					? convertNumberToNumberStringWithDot(data.product_plan.NR_grams)
					: undefined,
			},
			file_id: file?.id,
		};
	};

	const onSubmit = (data: FormT, force?: boolean) => {
		create({
			productTechMapRequestCreate: prepareRequest(data),
			'x-disable-catalog-num-remainder-check': force ? 'true' : undefined,
		})
			.unwrap()
			.then((data) => {
				showSuccessToast(toast, {
					title: 'Маршрутная карта создана',
					description: `Вы создали карту № ${getTechCardName(data)}`,
					duration: 6000,
				});
				onClose();
				onCreate(data);
			})
			.catch((error) => {
				if (error.status === 479) {
					setSubmitModal({
						remainder: error?.data?.detail[0]?.remainder,
						standardConsumptionType: error?.data?.detail[0]?.standard_consumption_type,
					});
				} else {
					showErrorToast(toast, {
						title: 'Не удалось сохранить',
						description: 'При создании карты произошла ошибка',
					});
				}
			});
	};

	return (
		<SideModal
			title="Создание связи с новой МК"
			onClose={onClose}
			isOpen={isOpen}
			footer={
				<ButtonGroup>
					<Button
						size="md"
						variant="ghost"
						colorScheme="tertiary"
						onClick={() => {
							onClose();
						}}
					>
						Отменить
					</Button>
					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						onClick={() => {
							formRef?.current?.onSubmit();
						}}
						isDisabled={isLoading}
						isLoading={isLoading}
					>
						Создать
					</Button>
				</ButtonGroup>
			}
		>
			{!isFetching && <TechCardForm ref={formRef} onSubmit={onSubmit} product={selectedProduct} />}
			{submitModal && (
				<SubmitModal
					isOpen={!!submitModal}
					onClose={() => setSubmitModal(null)}
					onSubmit={() => formRef.current && onSubmit(formRef?.current?.getValues(), true)}
					text={`Сейчас на складе ${submitModal.remainder} ${
						STANDARD_CONSUMPTION_TYPES[submitModal.standardConsumptionType]
					}. материала. Вы уверены, что хотите создать карту с таким материалом?`}
					closeBtnText="Изменить материал"
					isLoading={isLoading}
					submitBtnText="Создать карту"
				/>
			)}
		</SideModal>
	);
};
