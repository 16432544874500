import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import type { ColumnsType, FilterValue, SorterResult } from 'antd/es/table/interface';
import { ProductCatalogTableProps } from './product-catalog-table.types';

import { Actions, CommonTable, DeleteModal, Loader, showErrorToast, showSuccessToast } from '@/shared';
import { SortingDirection, useToast } from '@chakra-ui-kraud/react';
import { TablePaginationConfig } from 'antd';

import { CellTableParagraph } from '@/shared/components/cell-table-paragraph/cell-table-paragraph';
import {
	CustomProductDomain,
	NumCatalogSortingFields,
	ProductCatalogResponse,
	ProductCatalogSortingFields,
	useDeleteProductMutation,
} from '@/shared/state/api/swagger';
import styles from './product-catalod-table.module.scss';

export const ProductCatalogTable: FC<ProductCatalogTableProps> = ({
	products,
	onPageChange,
	page,
	pageSize,
	total,
	setQueryParams,
	isLoading,
	handleClick,
}) => {
	const toast = useToast();
	const [isProductDeleteModalOpen, setProductDeleteModalOpen] = useState<boolean>(false);
	const [selectedProduct, setSelectedProduct] = useState<ProductCatalogResponse | null>(null);
	const [deleteProduct] = useDeleteProductMutation();

	const handleDeleteProduct = (productId: number) => {
		deleteProduct({ productId })
			.unwrap()
			.then(() => {
				showSuccessToast(toast, { description: 'Деталь удаленa' });
			})
			.catch(() => {
				showErrorToast(toast, { description: 'При удалении детали произошла ошибка' });
			});
		setProductDeleteModalOpen(false);
	};

	const PRODUCT_CATALOG_COLUMNS: ColumnsType<ProductCatalogResponse> = useMemo(() => {
		const onHeaderCell: ColumnsType<ProductCatalogResponse>[number]['onHeaderCell'] = (column) => {
			return {
				style: {
					width: '100%',
					whiteSpace: 'normal',
				},
			};
		};

		return [
			{
				key: 'symbol',
				title: 'Обозначение детали',
				dataIndex: 'symbol',
				sorter: true,
				className: styles['symbol-table'],
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => <CellTableParagraph value={value} />,
				onHeaderCell,
				fixed: 'left',
			},
			{
				key: 'name',
				title: 'Наименование детали',
				dataIndex: 'name',
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => <CellTableParagraph value={value} maxWidth={window.innerWidth / 3} />,
				onHeaderCell,
			},
			{
				key: 'material_symbol',
				title: 'Обозначение материала',
				dataIndex: ['material', 'symbol'],
				className: styles['material-symbol'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => <CellTableParagraph value={value} />,
				onHeaderCell,
			},
			{
				key: 'material_gost',
				title: <>ТУ&#44; ГОСТ</>,
				dataIndex: ['material', 'gost'],
				className: styles['material-gost'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => (
					<CellTableParagraph
						value={value}
						//  minWidth={80}
					/>
				),
				onHeaderCell,
			},
			{
				key: 'material_sortament',
				title: 'Сортамент',
				dataIndex: ['material', 'sortament'],
				className: styles['material-sortament'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => <CellTableParagraph value={value} />,
				onHeaderCell,
			},
			{
				key: 'material_gost_na_sortament',
				title: <>ТУ&#44; ГОСТ на сортамент</>,
				dataIndex: ['material', 'gost_na_sortament'],
				className: styles['material-gost-na-sortament'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => <CellTableParagraph value={value} />,
				onHeaderCell,
			},
			{
				key: 'standard_consumption_meters',
				title: <>НР на ед&#44; м</>,
				align: 'left',
				dataIndex: 'standard_consumption_meters',
				className: styles['standard-consumption'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: number) => {
					const preparValue = value ? String(value).replace('.', ',') : undefined;
					return <CellTableParagraph value={preparValue} textAlign={'right'} />;
				},
				onHeaderCell,
			},
			{
				key: 'standard_consumption_square_meters',
				title: <>НР на ед&#44; м2</>,
				align: 'left',
				dataIndex: 'standard_consumption_square_meters',
				className: styles['standard-consumption'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: number) => {
					const preparValue = value ? String(value).replace('.', ',') : undefined;
					return <CellTableParagraph value={preparValue} textAlign={'right'} />;
				},
				onHeaderCell,
			},
			{
				key: 'standard_consumption_kg',
				title: <>НР на ед&#44; кг</>,
				align: 'left',
				dataIndex: 'standard_consumption_kg',
				className: styles['standard-consumption'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: number) => (
					<CellTableParagraph value={value ? String(value).replace('.', ',') : '-'} textAlign={'right'} />
				),
				onHeaderCell,
			},
			{
				key: 'standard_consumption_grams',
				title: <>НР на ед&#44; г</>,
				align: 'left',
				dataIndex: 'standard_consumption_grams',
				className: styles['standard-consumption'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: number) => (
					<CellTableParagraph value={value ? String(value).replace('.', ',') : '-'} textAlign={'right'} />
				),
				onHeaderCell,
			},
			{
				key: 'actions',
				title: 'Действия',
				align: 'left',
				dataIndex: 'actions',
				render: (_: ReactNode, row: ProductCatalogResponse) => {
					return (
						<Actions
							width={120}
							onDelete={() => {
								setProductDeleteModalOpen(true);
								setSelectedProduct(row);
							}}
							onEdit={() => handleClick(row, 'edit')}
							withTemplate
							onCreate={() => handleClick(row, 'create')}
							tooltip={{
								duplicate: 'Создать похожую деталь',
								edit: 'Редактировать деталь',
								del: 'Удалить деталь',
							}}
						/>
					);
				},
				onHeaderCell,
				fixed: 'right',
			},
		];
	}, []);

	const handleTableChange = useCallback(
		(
			pagination: TablePaginationConfig,
			filters: Record<string, FilterValue | null>,
			sorter: SorterResult<Partial<CustomProductDomain>> | SorterResult<Partial<CustomProductDomain>>[],
		) => {
			const sortedBy = Array.isArray(sorter) ? sorter[0].column?.key?.toString() : sorter.column?.key?.toString();
			const sortOrder = Array.isArray(sorter) ? sorter[0].order : sorter.order;
			const preparedSortDir = sortOrder ? (sortOrder === 'ascend' ? 'asc' : 'desc') : undefined;

			setQueryParams((prev) => ({
				...prev,
				orderBy: sortedBy as ProductCatalogSortingFields | undefined,
				orderDir: preparedSortDir as SortingDirection | undefined,
			}));
		},
		[],
	);

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<CommonTable
						columns={PRODUCT_CATALOG_COLUMNS}
						data={products}
						onChange={handleTableChange}
						pageSize={pageSize}
						total={total}
						page={page}
						setQueryParams={setQueryParams}
					/>
					{isProductDeleteModalOpen &&
						createPortal(
							<DeleteModal
								isOpen={isProductDeleteModalOpen}
								onClose={() => setProductDeleteModalOpen(false)}
								onDelete={() => handleDeleteProduct(selectedProduct?.id || 0)}
								text={`Вы уверены, что хотите удалить деталь ${selectedProduct?.name || ''}?`}
								title={'Удаление детали'}
							/>,
							document.body,
						)}
				</>
			)}
		</>
	);
};
