import { RoutemapCloseAlert, RoutemapMissedAlert, RoutemapRequestError, TransferToWarehouse } from '@/entities';
import { Loader } from '@/shared';
import { useChangeStatusMutation, useGetEmployeeInitialsQuery, useGetTechMapQuery } from '@/shared/state/api/swagger';
import { IssueDetailsForm, OperationFillForm } from '@/widgets';
import { useParams } from 'react-router-dom';
import {
	TECH_CARD_CLOSED_STATUSES,
	TECH_CARD_IN_PROGRESS_STATUSES,
	TECH_CARD_ISSUE_DETAILS_STATUSES,
} from './model/tech-card-operation.types';

export const TechCardOperationPage = () => {
	const { productTechMapId } = useParams();
	const {
		data: techMap,
		isError: isTechMapError,
		isLoading: isTechMapLoading,
		error: techMapError,
		refetch: refetchTechMap,
	} = useGetTechMapQuery({ productTechMapId: Number(productTechMapId) });
	const { data: employees = [], isLoading: isEmployeesLoading } = useGetEmployeeInitialsQuery();
	const [changeStatus] = useChangeStatusMutation();

	const mappedEmployees = employees.map(({ id, initials }) => ({
		label: initials,
		value: id,
	}));

	if (isTechMapError) {
		if ('status' in techMapError && techMapError.status === 461) {
			return <RoutemapMissedAlert />;
		}
		return <RoutemapRequestError />;
	}
	if (isTechMapLoading || !techMap?.status || !techMap?.number || isEmployeesLoading || !techMap?.id) {
		return <Loader />;
	}
	// Если МК закрыта
	if (TECH_CARD_CLOSED_STATUSES.includes(techMap?.status)) {
		return <RoutemapCloseAlert productTechMapNumber={techMap?.number} />;
	}
	return (
		<div>
			{TECH_CARD_ISSUE_DETAILS_STATUSES.includes(techMap?.status) && (
				<IssueDetailsForm mappedEmployees={mappedEmployees} techMap={techMap} />
			)}
			{TECH_CARD_IN_PROGRESS_STATUSES.includes(techMap.status) &&
				(techMap.current_product_operation ? (
					// Если у МК есть текущая операция, то окно заполнения операции
					<OperationFillForm mappedEmployees={mappedEmployees} techMap={techMap} />
				) : (
					// Иначе окно передачи на склад
					<TransferToWarehouse
						handleTransferToWarehouse={() => {
							changeStatus({
								productTechMapChangeStatusRequest: {
									status: 'accepted_at_sgd',
									product_tech_map_id: Number(techMap?.id),
								},
							});
							refetchTechMap();
						}}
					/>
				))}
		</div>
	);
};
