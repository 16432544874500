import classnames from 'classnames';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { matchPath } from 'react-router-dom';

import dayjs from 'dayjs';
import { OperationsBlockTypes } from '../model/operations.types';

import { chakra, IconButton, Input, Table, Td, Text, Tr } from '@chakra-ui-kraud/react';
import { DatePicker, Select } from 'antd';

import { TECH_CARD_NEW, UserRoles, useTechCard } from '@/shared';
import { useAppSelector } from '@/shared/state';
import { MadIcon } from 'madsoft-icons';

import styles from './custom-operations.module.scss';
import clsx from 'clsx';
import defaultStyles from '../../../tech-card.module.scss';
import { FormT } from '@/pages/tech-card-page/formConfig';
import { ProductOperationDomain } from '@/shared/state/api/swagger';
import { CustomOperationsHeader } from './custom-operations-header';
import { convertNumberToNumberStringWithDot } from '@/shared/core/utils/convert-string-to-number-string';

export const CustomOperationsBlock: FC<OperationsBlockTypes> = ({
	isEditable,
	employee,
	historedOperations,
	renderCancelButton,
	renderRenewOperationButton,
	setSideModalLinkOperation,
	focusedBlock,
	setFocusedBlock,
}) => {
	const { cardInfo } = useTechCard();

	const UserRole = useAppSelector((state) => state.auth.userProfile?.role);
	const { control, setValue, getValues } = useFormContext<FormT>();

	const { product_operations: fields } = getValues();

	const isCreationMode = !!matchPath(TECH_CARD_NEW, location.pathname) && !cardInfo;

	const sortedOperationsTypes = fields ? [...fields].sort((a, b) => Number(a?.step_id) - Number(b?.step_id)) : [];

	const customIndex =
		Number(cardInfo?.product_operations?.length) + Number(cardInfo?.default_product_operations?.length);
	const customOperations = [{}, {}] as FormT['custom_operations'];

	// определение завершенной операции (если есть ФИО и дата) и пустого поля
	const isOperationDoneAndEmptyField = (
		operation: ProductOperationDomain,
		fieldValue: string | number | null | undefined,
	) => {
		if (!!operation.fio?.length && !!operation.date?.length && !fieldValue) {
			return true;
		}
		return false;
	};

	const emptyRows = (length: number) => {
		const rows = new Array(length).fill({});
		return rows.map((_, idx) => (
			<Tr key={idx}>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
			</Tr>
		));
	};

	// определение завершенной операции (если есть ФИО и дата)
	const isOperationDone = (operation: ProductOperationDomain) => {
		if (!!operation.fio?.length && !!operation.date?.length) {
			return true;
		}
		return false;
	};

	return (
		<Table
			className={clsx(
				focusedBlock?.block === 'operations' &&
					focusedBlock.focusedBy === 'tab' &&
					!focusedBlock.atCurrentBlock &&
					defaultStyles.focused,
				isEditable && styles['edit-table'],
			)}
			onFocus={() => setFocusedBlock && setFocusedBlock('operations')}
		>
			<CustomOperationsHeader />
			{!isEditable && !isCreationMode && (
				<>
					{cardInfo?.product_operations?.map((operation) => (
						<Tr key={operation.id} className={operation.is_canceled ? styles['field-disabled'] : ''}>
							<Td style={{ textAlign: 'left' }}>
								{operation.step_id < 10 ? '0' + operation.step_id : operation.step_id}
							</Td>
							<Td style={{ textAlign: 'left', padding: '0px 2px 0px 8px' }}>
								{!operation.has_link ? (
									<Text>{`${operation.name} ${
										operation.is_canceled ? '— операция отменена' : ''
									}`}</Text>
								) : (
									<chakra.div display="flex" justifyContent="space-between" alignItems="center" p={0}>
										<chakra.div>
											{operation.name}
											{operation.id && <sup>{historedOperations[operation.id]}</sup>}
											{operation.is_canceled ? '— операция отменена' : ''}
										</chakra.div>
										<IconButton
											aria-label=""
											size="sm"
											variant="ghost"
											colorScheme="primary"
											onClick={() =>
												setSideModalLinkOperation({
													isOpen: true,
													title: `${operation.step_id} - ${operation.name}`,
													operationId: Number(operation.id),
												})
											}
											icon={
												<MadIcon
													module="basic"
													size="default"
													type="outline"
													mode="default"
													name="link"
												/>
											}
										/>
									</chakra.div>
								)}
							</Td>
							<Td>{dayjs(operation.date).isValid() ? dayjs(operation.date).format('DD.MM.YYYY') : ''}</Td>
							<Td>
								{!isOperationDoneAndEmptyField(operation, operation.count_in_gram)
									? operation.count_in_gram
									: '-'}
							</Td>
							<Td>
								{!isOperationDoneAndEmptyField(operation, operation.count_in_number)
									? operation.count_in_number
									: '-'}
							</Td>
							<Td>{operation.fio}</Td>
							<Td>
								{!isOperationDoneAndEmptyField(operation, operation.count_out_gram)
									? operation.count_out_gram
									: '-'}
							</Td>
							<Td>
								{!isOperationDoneAndEmptyField(operation, operation.count_out_number)
									? operation.count_out_number
									: '-'}
							</Td>
							<Td>{!operation.wasted ? '' : operation.wasted}</Td>
							<Td></Td>
							<Td></Td>
						</Tr>
					))}
					{emptyRows(2)}
				</>
			)}
			{!isEditable && isCreationMode && sortedOperationsTypes?.length ? (
				<>
					{sortedOperationsTypes?.map((operation) => (
						<Tr key={operation.id} className={operation.is_canceled ? styles['field-disabled'] : ''}>
							<Td style={{ textAlign: 'left' }}>
								{operation.step_id < 10 ? '0' + operation.step_id : operation.step_id}
							</Td>
							<Td style={{ textAlign: 'left' }}>{operation.name}</Td>
							<Td></Td>
							<Td></Td>
							<Td></Td>
							<Td></Td>
							<Td></Td>
							<Td></Td>
							<Td></Td>
							<Td></Td>
							<Td></Td>
						</Tr>
					))}

					{emptyRows(2)}
				</>
			) : (
				<></>
			)}
			{isEditable &&
				!isCreationMode &&
				fields?.map((field, index) => (
					<Tr key={field.id}>
						<Td className={field.is_canceled ? styles['field-disabled'] : ''} style={{ textAlign: 'left' }}>
							{!field.is_canceled ? (
								<Controller
									name={`product_operations.${index}.step_id`}
									control={control}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<Input
											backgroundColor="transparent"
											tabIndex={7}
											isInvalid={!!error}
											onChange={onChange}
											value={value}
											name={`product_operations.${index}.step_id`}
										/>
									)}
								/>
							) : (
								<Text p="6px">{field.step_id}</Text>
							)}
						</Td>
						<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
							<chakra.div
								w="100%"
								h="100%"
								display="flex"
								justifyContent="space-between"
								alignItems="center"
							>
								{!field.is_canceled ? (
									<Controller
										name={`product_operations.${index}.name`}
										control={control}
										render={({ field: fieldData, fieldState: { error } }) => {
											return (
												<Input
													tabIndex={7}
													isInvalid={!!error}
													{...fieldData}
													h="100%"
													flex="1"
												/>
											);
										}}
									/>
								) : (
									<Text
										textAlign="left"
										padding={field.is_canceled ? '8px 1px 8px 6px' : ''}
										fontWeight="normal"
									>
										{field.name}
									</Text>
								)}
								<Controller
									name={`product_operations.${index}.is_canceled`}
									control={control}
									render={() => {
										// если в операции нет даты или нет ФИО исполнителя или операция уже отменена
										if (!field.date || !field.fio || field.is_canceled) {
											if (field.is_canceled) {
												return <>{renderRenewOperationButton(field, 'operation')}</>;
											} else return <>{renderCancelButton(field, 'operation')}</>;
										} else return <></>;
									}}
								/>
							</chakra.div>
						</Td>
						<Td className={clsx(styles['date-style'], field.is_canceled && styles['field-disabled'])}>
							{!field.is_canceled ? (
								<Controller
									name={`product_operations.${index}.date`}
									control={control}
									render={({ field: { onChange }, fieldState: { error } }) => (
										<DatePicker
											style={{ width: '100%', height: '100%', padding: '0' }}
											tabIndex={7}
											status={error ? 'error' : ''}
											onChange={onChange}
											format={'DD.MM.YYYY'}
											defaultValue={
												field.date && dayjs(field.date).isValid()
													? dayjs(field.date)
													: undefined
											}
											name={`product_operations.${index}.date`}
										/>
									)}
								/>
							) : (
								<Text fontWeight="normal" textAlign="left">
									{field.date ? dayjs(field.date).format('DD.MM.YYYY') : ''}
								</Text>
							)}
						</Td>
						<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
							{!field.is_canceled && (!isOperationDone(field) || UserRole === UserRoles.admin) ? (
								<Controller
									name={`product_operations.${index}.count_in_gram`}
									control={control}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<Input
											backgroundColor="transparent"
											tabIndex={7}
											onChange={onChange}
											isInvalid={!!error}
											value={value}
											name={`product_operations.${index}.count_in_gram`}
										/>
									)}
								/>
							) : (
								<Text
									p="6px"
									fontWeight="normal"
									textAlign={
										!isOperationDoneAndEmptyField(field, field.count_in_gram) ? 'left' : 'center'
									}
								>
									{!isOperationDoneAndEmptyField(field, field.count_in_gram)
										? field.count_in_gram
										: '-'}
								</Text>
							)}
						</Td>
						<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
							{!field.is_canceled && (!isOperationDone(field) || UserRole === UserRoles.admin) ? (
								<Controller
									name={`product_operations.${index}.count_in_number`}
									control={control}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<Input
											backgroundColor="transparent"
											tabIndex={7}
											onChange={onChange}
											isInvalid={!!error}
											value={value}
											name={`product_operations.${index}.count_in_number`}
										/>
									)}
								/>
							) : (
								<Text
									p="6px"
									fontWeight="normal"
									textAlign={
										!isOperationDoneAndEmptyField(field, field.count_in_number) ? 'left' : 'center'
									}
								>
									{!isOperationDoneAndEmptyField(field, field.count_in_number)
										? field.count_in_number
										: '-'}
								</Text>
							)}
						</Td>
						<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
							{!field.is_canceled ? (
								<Controller
									name={`product_operations.${index}.fio`}
									control={control}
									render={({ field: { value, onChange } }) => (
										<Select
											tabIndex={7}
											filterOption={(input, option) =>
												(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
											}
											allowClear
											showSearch
											placeholder="Выберите ФИО"
											optionFilterProp="children"
											value={value}
											onChange={(e) => {
												onChange(e);
												setValue(
													`product_operations.${index}.fio`,
													e ? employee.find((el) => el.value === Number(e))?.label : '',
												);
											}}
											options={employee}
											style={{ width: '100%' }}
											className={classnames(styles['custom-select'])}
										/>
									)}
								/>
							) : (
								<Text p="6px" fontWeight="normal" textAlign="center">
									{field.fio}
								</Text>
							)}
						</Td>
						<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
							{!field.is_canceled && (!isOperationDone(field) || UserRole === UserRoles.admin) ? (
								<Controller
									name={`product_operations.${index}.count_out_gram`}
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
										formState: { defaultValues },
									}) => (
										<Input
											backgroundColor="transparent"
											tabIndex={7}
											isInvalid={!!error}
											onChange={(e) => {
												onChange(e);
												// если это последняя операция перед дефолтными (у дефолтных нельзя менять имена)
												// или если после операции есть только отмененные операции, но есть еще и дефолтные (не отмененные)

												if (
													fields.length - 2 === index ||
													fields[fields.length - 2].is_canceled
												) {
													// перенос годных из "Контрольная ПСИ" в  раздел Кол-во "Сдача на СГД"
													// гр., предпоследняя кастомная операция -> первая дефолтная операция
													setValue(
														'default_product_operations.0.count_in_gram',
														Number(convertNumberToNumberStringWithDot(e.target.value)),
													);
												} else if (
													fields.length - 1 === index ||
													fields[fields.length - 1].is_canceled
												) {
													// перенос годных из  "Консервации" в раздел Кол-во "Приёмка на СГД"
													// гр.,последная кастомная операция  -> вторая дефолтная операция
													setValue(
														'default_product_operations.1.count_in_gram',
														Number(convertNumberToNumberStringWithDot(e.target.value)),
													);
												} else {
													// поиск следующей неотмененной операции
													const currentOperationIndex = fields.findIndex(
														(el) => el.step_id > field.step_id && !el.is_canceled,
													);

													if (
														currentOperationIndex > -1 &&
														!defaultValues?.product_operations?.[currentOperationIndex]
															?.count_in_gram
													) {
														// если это не последняя операция, расчет годных (грамм) в этой операции переходит в грамм, штук следующей НЕ ОТМЕНЕННОЙ операции
														setValue(
															`product_operations.${currentOperationIndex}.count_in_gram`,
															Number(convertNumberToNumberStringWithDot(e.target.value)),
														);
													}
												}
											}}
											value={value}
											name={`product_operations.${index}.count_out_gram`}
										/>
									)}
								/>
							) : (
								<Text
									p="6px"
									fontWeight="normal"
									textAlign={
										!isOperationDoneAndEmptyField(field, field.count_out_gram) ? 'left' : 'center'
									}
								>
									{!isOperationDoneAndEmptyField(field, field.count_out_gram)
										? field.count_out_gram
										: '-'}
								</Text>
							)}
						</Td>
						<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
							{!field.is_canceled && (!isOperationDone(field) || UserRole === UserRoles.admin) ? (
								<Controller
									name={`product_operations.${index}.count_out_number`}
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
										formState: { defaultValues },
									}) => (
										<Input
											backgroundColor="transparent"
											tabIndex={7}
											isInvalid={!!error}
											onChange={(e) => {
												onChange(e);
												// если это последняя операция перед дефолтными (у дефолтных нельзя менять имена)
												// или если после операции есть только отмененные операции, но есть еще и дефолтные (не отмененные)
												if (
													fields.length - 2 === index ||
													fields[fields.length - 2].is_canceled
												) {
													// перенос годных из "Контрольная ПСИ" в  раздел Кол-во "Сдача на СГД"
													// шт., предпоследняя кастомная операция -> первая дефолтная операция
													setValue(
														'default_product_operations.0.count_in_number',
														Number(convertNumberToNumberStringWithDot(e.target.value)),
													);
												} else if (
													fields.length - 1 === index ||
													fields[fields.length - 1].is_canceled
												) {
													// перенос годных из  "Консервации" в раздел Кол-во "Приёмка на СГД"
													// шт.,последная кастомная операция  -> вторая дефолтная операция
													setValue(
														'default_product_operations.1.count_in_number',
														Number(convertNumberToNumberStringWithDot(e.target.value)),
													);
												} else {
													// поиск следующей неотмененной операции
													const currentOperationIndex = fields.findIndex(
														(el) => el.step_id > field.step_id && !el.is_canceled,
													);

													if (
														currentOperationIndex > -1 &&
														!defaultValues?.product_operations?.[currentOperationIndex]
															?.count_in_number
													) {
														// если это не последняя операция, расчет годных (штук) в этой операции переходит в грамм, штук следующей НЕ ОТМЕНЕННОЙ операции
														setValue(
															`product_operations.${currentOperationIndex}.count_in_number`,
															Number(convertNumberToNumberStringWithDot(e.target.value)),
														);
													}
												}

												if (!e.target.value) {
													setValue(
														// остаток от (всего штук, грамм - годны штук, грамм) переходит в брак (штук) этой операции
														`product_operations.${index}.wasted`,
														undefined,
													);
												} else {
													setValue(
														// остаток от (всего штук, грамм - годны штук, грамм) переходит в брак (штук) этой операции
														`product_operations.${index}.wasted`,
														Number(
															getValues(`product_operations.${index}.count_in_number`),
														) - Number(e.target.value),
													);
												}
											}}
											value={value}
											name={`product_operations.${index}.count_out_number`}
										/>
									)}
								/>
							) : (
								<Text
									p="6px"
									fontWeight="normal"
									textAlign={
										!isOperationDoneAndEmptyField(field, field.count_out_number) ? 'left' : 'center'
									}
								>
									{!isOperationDoneAndEmptyField(field, field.count_out_number)
										? field.count_out_number
										: '-'}
								</Text>
							)}
						</Td>
						<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
							{!field.is_canceled ? (
								<Controller
									name={`product_operations.${index}.wasted`}
									control={control}
									render={({ field: { onChange, value, name }, fieldState: { error } }) => (
										<Input
											isReadOnly={
												UserRole === UserRoles.operator ||
												UserRole === UserRoles.senior_operator
											}
											tabIndex={7}
											isInvalid={!!error}
											onChange={onChange}
											value={value && value > 0 ? value : ''}
											name={name}
											className={field.is_canceled ? styles['field-disabled'] : ''}
										/>
									)}
								/>
							) : (
								<Text p="6px" fontWeight="normal" textAlign="left">
									{!field.wasted ? '' : field.wasted}
								</Text>
							)}
						</Td>
						<Td className={field.is_canceled ? styles['field-disabled'] : ''}></Td>
						<Td className={field.is_canceled ? styles['field-disabled'] : ''}></Td>
					</Tr>
				))}

			{/* Пустые строки в таблице */}
			{isEditable &&
				!isCreationMode &&
				customOperations?.map((field, index) => (
					<Tr key={customIndex + index}>
						<Td>
							<Controller
								name={`custom_operations.${customIndex + index}.step_id`}
								control={control}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<Input
										tabIndex={7}
										isInvalid={!!error}
										onChange={onChange}
										value={value}
										name={`custom_operations.${customIndex + index}.step_id`}
									/>
								)}
							/>
						</Td>
						<Td>
							<Controller
								name={`custom_operations.${customIndex + index}.name`}
								control={control}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<Input
										tabIndex={7}
										isInvalid={!!error}
										onChange={onChange}
										value={value}
										name={`custom_operations.${customIndex + index}.name`}
									/>
								)}
							/>
						</Td>
						<Td>
							<Controller
								name={`custom_operations.${customIndex + index}.date`}
								control={control}
								render={({ field: { onChange }, fieldState: { error } }) => (
									<DatePicker
										style={{ width: '100%' }}
										tabIndex={7}
										status={error ? 'error' : ''}
										onChange={onChange}
										defaultValue={
											field.date && dayjs(field.date).isValid() ? dayjs(field.date) : undefined
										}
										format={'DD.MM.YYYY'}
										name={`custom_operations.${customIndex + index}.date`}
									/>
								)}
							/>
						</Td>
						<Td>
							<Controller
								name={`custom_operations.${customIndex + index}.count_in_gram`}
								control={control}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<Input
										tabIndex={7}
										onChange={onChange}
										isInvalid={!!error}
										value={value}
										name={`custom_operations.${customIndex + index}.count_in_gram`}
									/>
								)}
							/>
						</Td>
						<Td>
							<Controller
								name={`custom_operations.${customIndex + index}.count_in_number`}
								control={control}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<Input
										tabIndex={7}
										onChange={onChange}
										isInvalid={!!error}
										value={value}
										name={`custom_operations.${customIndex + index}.count_in_number`}
									/>
								)}
							/>
						</Td>
						<Td>
							<Controller
								name={`custom_operations.${customIndex + index}.fio`}
								control={control}
								render={({ field: { value, onChange } }) => (
									<Select
										allowClear
										tabIndex={7}
										filterOption={(input, option) =>
											(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										}
										showSearch
										placeholder="Выберите ФИО"
										optionFilterProp="children"
										value={value}
										onChange={(e) => {
											onChange(e);
											setValue(
												`custom_operations.${customIndex + index}.fio`,
												e ? employee.find((el) => el.value === Number(e))?.label : '',
											);
										}}
										options={employee}
										style={{ width: '100%' }}
										className={styles['custom-select']}
									/>
								)}
							/>
						</Td>
						<Td>
							<Controller
								name={`custom_operations.${customIndex + index}.count_out_gram`}
								control={control}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<Input
										tabIndex={7}
										isInvalid={!!error}
										onChange={onChange}
										value={value}
										name={`custom_operations.${customIndex + index}.count_out_gram`}
									/>
								)}
							/>
						</Td>
						<Td>
							<Controller
								name={`custom_operations.${customIndex + index}.count_out_number`}
								control={control}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<Input
										tabIndex={7}
										isInvalid={!!error}
										onChange={onChange}
										value={value}
										name={`custom_operations.${customIndex + index}.count_out_number`}
									/>
								)}
							/>
						</Td>
						<Td>
							<Controller
								name={`custom_operations.${customIndex + index}.wasted`}
								control={control}
								render={({ field: { onChange, value, name }, fieldState: { error } }) => (
									<Input
										isReadOnly={
											UserRole === UserRoles.operator || UserRole === UserRoles.senior_operator
										}
										tabIndex={7}
										isInvalid={!!error}
										onChange={onChange}
										value={value && value > 0 ? value : ''}
										name={name}
									/>
								)}
							/>
						</Td>
						<Td></Td>
						<Td></Td>
					</Tr>
				))}
		</Table>
	);
};
