import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import type { FC } from 'react';
import type { ITechCardTable, TechCardsFiltersArg, TechMapData } from './tech-card-table.types';

import { Badge, useToast } from '@chakra-ui-kraud/react';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { TechCardActions } from '@/widgets/tech-card-actions';
import { CommonTable } from '@/features/common-table';
import { TechCardStatusColors } from '@/shared';
import { Loader } from '@/shared/components/loader';
import { CellTableParagraph } from '@/shared/components/cell-table-paragraph/cell-table-paragraph';
import { showErrorToast } from '@/shared/components/toasts';
import { formatCurrentOperation } from '@/shared/core/utils/tech-card-utils';
import {
	LINK_TO_TECH_CARD_ASSEMBLY_INFO,
	LINK_TO_TECH_CARD_INFO,
	TechCardStatus,
	TechCardStatuses,
} from '@/shared/core';
import { ProductTechMapResponse, useGetTechMapExcelMutation } from '@/shared/state/api/swagger';

import styles from './tech-card-table.module.scss';
import { Row } from 'antd';

const MAP_STATUS_TO_NAME: Record<TechCardStatus, string | undefined> = {
	[TechCardStatuses.released]: 'Отгружено',
	[TechCardStatuses.progress]: 'В работе',
	[TechCardStatuses.accepted_at_sgd]: 'Принято на СГД',
	[TechCardStatuses.canceled]: 'Аннулирована',
	[TechCardStatuses.partially_issued]: 'Частично выдана',
	[TechCardStatuses.completed]: 'Завершена',
};

export const TechCardsTable: FC<ITechCardTable> = ({
	data,
	isLoading,
	superHeader,
	setQueryParams,
	queryParams,
	type,
}) => {
	const toast = useToast();

	const [getExcel] = useGetTechMapExcelMutation();

	const [activeMenuItem, setActiveMenuItem] = useState<null | number>(null);

	const getFile = useCallback((value: ProductTechMapResponse) => {
		value.number &&
			getExcel({ productTechMapNumber: value.number })
				.unwrap()
				.then((res) => {
					const href = window.URL.createObjectURL(new Blob([res]));
					const link = document.createElement('a');
					link.href = href;
					link.download = `${value}.xlsx`;
					link.click();
					link.remove();
				})
				.catch(() => {
					showErrorToast(toast, { description: 'При скачивании Excel произошла ошибка' });
				});
	}, []);

	const TECH_CARD_COLUMNS: ColumnsType<ProductTechMapResponse> = useMemo(() => {
		const onHeaderCell: ColumnsType<ProductTechMapResponse>[number]['onHeaderCell'] = (column) => ({
			style: {
				width: '100%',
				whiteSpace: 'normal',
			},
		});

		return [
			{
				key: 'group',
				dataIndex: 'group',
				title: 'Группа',
				align: 'left',
				className: styles['group'],
				sorter: false,
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (_, row) => <CellTableParagraph value={row?.symbol} />,
				fixed: 'left',
			},
			{
				key: 'number',
				title: 'Номер карты',
				dataIndex: 'number',
				align: 'left',
				className: styles['number'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (value) => (
					<Link
						color="red"
						className={styles.link}
						to={type === 'product' ? LINK_TO_TECH_CARD_INFO(value) : LINK_TO_TECH_CARD_ASSEMBLY_INFO(value)}
					>
						<CellTableParagraph value={value} />
					</Link>
				),
				fixed: 'left',
			},
			{
				key: `${type}_catalog.symbol`,
				dataIndex: `${type}_catalog.symbol`,
				title: 'Обозначение',
				sorter: true,
				className: styles['labeling'],
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (_, row) => <CellTableParagraph value={row?.product?.symbol} />,
			},
			{
				key: `${type}_catalog.name`,
				dataIndex: `${type}_catalog.name`,
				title: 'Наименование',
				className: styles['naming'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (_, row) => <CellTableParagraph value={row?.product?.name} />,
			},
			{
				key: `current_${type}_operation`,
				dataIndex: `current_${type}_operation`,
				title: 'Текущая операция',
				className: styles['current_product_operation'],
				sorter: false,
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (_, row) => (
					<CellTableParagraph
						value={formatCurrentOperation(
							row?.current_product_operation?.step_id,
							row?.current_product_operation?.name,
						)}
					/>
				),
			},
			{
				key: 'status',
				dataIndex: 'status',
				title: 'Статус',
				sorter: true,
				className: styles['status'],
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (value: TechCardStatuses) => (
					<Badge
						variant={'solid'}
						background={TechCardStatusColors[value]}
						borderRadius="6px"
						textTransform="initial"
						fontFamily="Raleway"
						lineHeight="16px"
						fontWeight={600}
						padding="4px 8px 4px 8px"
						style={{ width: 'fit-content' }}
					>
						<CellTableParagraph value={MAP_STATUS_TO_NAME[value]} />
					</Badge>
				),
			},

			{
				key: 'created_at',
				dataIndex: 'created_at',
				title: 'Дата создания',
				align: 'left',
				sorter: true,
				className: styles['created-at'],
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (value) => <CellTableParagraph value={dayjs(value).format('DD.MM.YYYY')} textAlign={'right'} />,
			},
			{
				key: 'storage_at',
				dataIndex: 'storage_at',
				title: 'Дата принятия на СГД',
				align: 'left',
				sorter: true,
				className: styles['storage-at'],
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (value) => (
					<CellTableParagraph
						value={dayjs(value).isValid() ? dayjs(value).format('DD.MM.YYYY') : undefined}
						textAlign={'right'}
					/>
				),
			},
			{
				key: 'current_details_count',
				dataIndex: 'current_details_count',
				title: 'Годных',
				align: 'left',
				sorter: false,
				className: styles['current-details-count'],
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (value) => <CellTableParagraph value={value} textAlign={'right'} />,
			},
			{
				key: 'wasted',
				dataIndex: 'wasted',
				title: 'Брак',
				align: 'left',
				sorter: true,
				className: styles['wasted'],
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (value) => <CellTableParagraph value={value && value > 0 ? value : ''} textAlign={'right'} />,
			},
			{
				key: 'remaining_count',
				dataIndex: 'remaining_count',
				title: 'На складе',
				align: 'left',
				sorter: true,
				className: styles['remaining_count'],
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (value) => (
					<CellTableParagraph noFormatting value={value === null ? '\u2014' : value} textAlign={'right'} />
				),
			},
			{
				key: 'actions',
				dataIndex: 'number',
				title: 'Действия',
				align: 'center',
				onHeaderCell,
				width: '110px',
				render: (_, row) => {
					return row.id ? (
						<TechCardActions
							type={type}
							onExcel={getFile}
							status={row.status}
							value={row}
							activeMenuItem={activeMenuItem}
							setActiveMenuItem={setActiveMenuItem}
							itemId={row.id}
						/>
					) : (
						<></>
					);
				},
				fixed: 'right',
			},
		];
	}, [activeMenuItem, getFile, type]);

	const handleTableChange = useCallback(
		(
			pagination: TablePaginationConfig,
			filters: Record<string, FilterValue | null>,
			sorter: SorterResult<TechMapData> | SorterResult<TechMapData>[],
		) => {
			const sortedBy = (
				Array.isArray(sorter) ? sorter[0].column?.key?.toString() : sorter.column?.key?.toString()
			) as TechCardsFiltersArg['orderBy'];

			const sortOrder = Array.isArray(sorter) ? sorter[0].order : sorter.order;
			const preparedSortDir = sortOrder ? (sortOrder === 'ascend' ? 'asc' : 'desc') : undefined;

			console.debug(sortedBy, sortOrder, preparedSortDir);

			setQueryParams((prev) => ({
				...prev,
				orderBy: sortedBy,
				orderDir: preparedSortDir,
			}));
		},
		[],
	);

	return !isLoading ? (
		<CommonTable
			superHeader={superHeader}
			columns={TECH_CARD_COLUMNS}
			data={data?.payload || []}
			onChange={handleTableChange}
			pageSize={queryParams.pageSize ?? 32}
			total={data?.total}
			page={data?.page}
			setQueryParams={setQueryParams}
		/>
	) : (
		<Loader />
	);
};
