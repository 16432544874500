import type { FC } from 'react';
import type { FilterModalProps } from './filter-modal.types';

import { useEffect, useState, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Flex, useToast } from '@chakra-ui-kraud/react';
import { DefaultOptionType } from 'antd/es/select';
import { Select } from 'antd';
import { Button, ButtonGroup, Checkbox, Radio, RadioGroup, Stack, Text } from '@chakra-ui-kraud/react';
import _debounce from 'lodash/debounce';

import { TechCardStatus, TechCardStatuses } from '@/shared/core';
import { CalendarRangePicker, NotFoundContentDropdown, showErrorToast, SideModal } from '@/shared/components';
import {
	useGetCurrentOperationsNamesQuery,
	useGetTechMapSymbolCatalogQuery,
	useLazyGetAssembliesQuery,
	useLazyGetProductNamesForFilterQuery,
	useLazyGetProductSymbolForFilterQuery,
} from '@/shared/state/api/swagger';

import styles from '../../shared/components/custom-select/custom-select.module.scss';
import clsx from 'clsx';
import { TechCardsFiltersArg } from '@/entities/tables/tech-card';

export const FilterModal: FC<FilterModalProps> = ({ isOpen, onClose, onSubmit, defaultValue, type }) => {
	// TODO - рефактор - вынести в отдельный компонент поля фильтров с Lazy loading и всей логикой стейтов
	// TODO - типизировать (убрать any из пропсов)

	const toast = useToast();

	// useState

	// // Группы
	const [groupVisibility, setGroupVisibility] = useState(false);
	const [groupOption, setGroupOption] = useState<DefaultOptionType[] | []>([]);

	// // Статусы
	const [statuses, setStatuses] = useState<TechCardStatus[]>(defaultValue?.statuses ?? []);
	// // Наименование
	const [namingVisibility, setNamingVisibility] = useState(false);
	const [namingOption, setNamingOption] = useState<DefaultOptionType[] | []>([]);
	const [namingSearch, setNamingSearch] = useState('');

	// // Текущая операция
	const [currOpVisibility, setCurrOpVisibility] = useState(false);
	const [currOpOption, setCurrOpOption] = useState<DefaultOptionType[] | []>([]);
	// // Обозначение
	const [labelingVisibility, setLabelingVisibility] = useState(false);
	const [labelingOption, setLabelingOption] = useState<DefaultOptionType[] | []>([]);
	const [labelingSearch, setLabelingSearch] = useState('');

	// RTKquery
	// // Группы
	const {
		data: groupsServerData,
		isError: isErrorGroups,
		isSuccess: isSuccessGroups,
	} = useGetTechMapSymbolCatalogQuery();

	// // Наименования
	const [
		triggerProductNaming,
		{ data: namingProductServerData, isError: isErrorProductNamingServer, isFetching: isFetchingProductNaming },
	] = useLazyGetProductNamesForFilterQuery();
	// // Наименования
	const [
		triggerAssemblyNaming,
		{ namingAssemblyServerData, isError: isErrorAssemblyNamingServer, isFetching: isFetchingAssemblyNaming },
	] = useLazyGetAssembliesQuery({
		selectFromResult: (result) => {
			return {
				...result,
				namingAssemblyServerData: {
					...result,
					payload: result?.data?.payload?.map((item) => item.name),
				},
			};
		},
	});

	// // Обозначение
	const [
		triggerProductSymbol,
		{
			data: productSymbolServerData,
			isError: isErrorProductSymbol,
			isFetching: isFetchingProductSymbol,
			isSuccess: isSuccessProductSymbol,
		},
	] = useLazyGetProductSymbolForFilterQuery();

	// // Обозначение
	const [
		triggerAssemblySymbol,
		{
			assemblySymbolServerData,
			isError: isErrorAssemblySymbol,
			isFetching: isFetchingAssemblySymbol,
			isSuccess: isSuccessAssemblySymbol,
		},
	] = useLazyGetAssembliesQuery({
		selectFromResult: (result) => {
			return {
				...result,
				assemblySymbolServerData: {
					...result,
					payload: result?.data?.payload?.map((item) => item.symbol),
				},
			};
		},
	});

	const queries = {
		product: {
			triggerNaming: triggerProductNaming,
			namingServerData: namingProductServerData,
			isErrorNamingServer: isErrorProductNamingServer,
			isFetchingNaming: isFetchingProductNaming,
			symbolServerData: productSymbolServerData,
			isFetchingSymbol: isFetchingProductSymbol,
			isErrorSymbol: isErrorProductSymbol,
			isSuccessSymbol: isSuccessProductSymbol,
			triggerSymbol: triggerProductSymbol,
		},
		assembly: {
			triggerNaming: triggerAssemblyNaming,
			namingServerData: namingAssemblyServerData,
			isErrorNamingServer: isErrorAssemblyNamingServer,
			isFetchingNaming: isFetchingAssemblyNaming,
			symbolServerData: assemblySymbolServerData,
			isErrorSymbol: isErrorAssemblySymbol,
			isFetchingSymbol: isFetchingAssemblySymbol,
			isSuccessSymbol: isSuccessAssemblySymbol,
			triggerSymbol: triggerAssemblySymbol,
		},
	};

	const {
		isErrorNamingServer,
		isFetchingNaming,
		namingServerData,
		triggerNaming,
		triggerSymbol,
		symbolServerData,
		isFetchingSymbol,
		isErrorSymbol,
		isSuccessSymbol,
	} = queries[type];

	// // Текущие операции
	const {
		data: currentOperationsList,
		isLoading: isCurrentOperationsLoading,
		isError: isCurrentOperationsError,
	} = useGetCurrentOperationsNamesQuery();
	// handlers
	const handleNamingInput = (e: any) => {
		e.target.value && triggerNaming({ stringToSearch: e.target.value.trim() });
	};
	const handleLabelingInput = (e: any) => {
		e.target.value && triggerSymbol({ stringToSearch: e.target.value.trim() });
	};

	const onStatusChange = (isChecked: boolean, status: TechCardStatus) => {
		if (isChecked) {
			setStatuses((prev) => [...prev, TechCardStatuses[status]]);
		} else {
			setStatuses((prev) => prev.filter((el) => el !== TechCardStatuses[status]));
		}
	};

	const { control, handleSubmit, setValue, getValues } = useForm<TechCardsFiltersArg>({
		defaultValues: {
			symbol: defaultValue?.symbol,
			[`${type}Name`]: defaultValue?.[`${type}Name`],
			currentOperationsNames: defaultValue?.currentOperationsNames,
			[`${type}Symbol`]: defaultValue?.[`${type}Symbol`],
			statuses: defaultValue?.statuses ?? [],
			wasted: defaultValue?.wasted,
			createdAtTo: defaultValue?.createdAtTo,
			createdAtFrom: defaultValue?.createdAtFrom,
			storageAtTo: defaultValue?.storageAtTo,
			storageAtFrom: defaultValue?.storageAtFrom,
		},
	});

	const selectCreatedDates = (firstDate: string, secondDate: string) => {
		setValue('createdAtFrom', firstDate);
		setValue('createdAtTo', secondDate);
	};

	const selectSGDDates = (firstDate: string, secondDate: string) => {
		setValue('storageAtFrom', firstDate);
		setValue('storageAtTo', secondDate);
	};

	const resetForm = () => {
		setValue('symbol', undefined);
		setValue(`${type}Name`, undefined);
		setValue('currentOperationsNames', undefined);
		setValue(`${type}Symbol`, undefined);
		setValue('statuses', undefined);
		setValue('wasted', undefined);
		setValue('createdAtFrom', undefined);
		setValue('createdAtTo', undefined);
		setValue('storageAtFrom', undefined);
		setValue('storageAtTo', undefined);

		handleSubmit(onSubmit)();
	};

	// debounce
	const debouncedNaming = useCallback(_debounce(handleNamingInput, 700), []);
	const debouncedLabeling = useCallback(_debounce(handleLabelingInput, 700), []);

	// prepare function
	// // Группы
	const getOptionByGroupServerData = (serverData: { symbol?: string; id?: number }[]) => {
		const result: DefaultOptionType[] | [] = [];

		serverData.forEach((item: any, index: number) => {
			if (item && item?.symbol) {
				const obj: DefaultOptionType = {
					value: item.symbol,
					label: item.symbol,
				};
				//@ts-ignore
				result.push(obj);
			}
		});

		return result;
	};

	// // Наименования
	const getOptionByNamingServerData = (serverData: any) => {
		return serverData.map((item: any, index: number) => {
			return {
				value: index + 1,
				label: item,
			};
		});
	};
	// // Текущие операции
	const getOptionByCurrOpServerData = (serverData: string[]): DefaultOptionType[] => {
		const result: DefaultOptionType[] = [];

		serverData.forEach((item, index) => {
			result.push({ label: item, value: item });
		});

		return result;
	};
	// // Обозначение
	const getOptionByLabelingServerData = (serverData: any) => {
		return serverData.map((item: any, index: number) => {
			return {
				value: index + 1,
				label: item,
			};
		});
	};

	useEffect(() => {
		if (isSuccessGroups) setGroupOption(getOptionByGroupServerData(groupsServerData));
	}, [groupsServerData?.length]);

	useEffect(() => {
		namingServerData?.payload &&
			setNamingOption([
				{ value: 0, label: namingSearch },
				...getOptionByNamingServerData(namingServerData?.payload),
			]);
	}, [namingServerData?.payload]);

	useEffect(() => {
		!isCurrentOperationsLoading &&
			currentOperationsList?.length &&
			setCurrOpOption([...getOptionByCurrOpServerData(currentOperationsList)]);
	}, [isCurrentOperationsLoading, currentOperationsList]);

	useEffect(() => {
		if (isSuccessSymbol)
			symbolServerData?.payload &&
				setLabelingOption([
					{ value: 0, label: labelingSearch },
					...getOptionByLabelingServerData(symbolServerData?.payload),
				]);
	}, [symbolServerData?.payload]);

	useEffect(() => {
		//@ts-expect-error
		setValue('statuses', statuses);
	}, [statuses]);

	// обработка исключений
	useEffect(() => {
		if (isErrorGroups) showErrorToast(toast, { description: 'При получении Групп произошла ошибка' });
		if (isErrorNamingServer) showErrorToast(toast, { description: 'При получении Наименований произошла ошибка' });
		if (isCurrentOperationsError)
			showErrorToast(toast, { description: 'При получении Текущих операций произошла ошибка' });
		if (isErrorSymbol) showErrorToast(toast, { description: 'При получении Обозначений произошла ошибка' });
	}, [isErrorGroups, isErrorNamingServer, isCurrentOperationsError, isErrorSymbol]);

	return (
		<SideModal
			isOpen={isOpen}
			onClose={onClose}
			title="Фильтры"
			footer={
				<ButtonGroup>
					<Button size="md" variant="ghost" colorScheme="tertiary" type="button" onClick={resetForm}>
						Сбросить
					</Button>
					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						type="submit"
						onClick={handleSubmit(onSubmit)}
					>
						Применить
					</Button>
				</ButtonGroup>
			}
			onBlur={onClose}
		>
			<form
				onSubmit={() => handleSubmit(onSubmit)}
				style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
			>
				<Controller
					control={control}
					name="symbol"
					render={({ field: { value }, fieldState: { error } }) => {
						return (
							<Flex flexDirection="column">
								<Flex flexDirection="row" justifyContent="space-between">
									<Text size="md" lineHeight="6" fontWeight="600" marginBottom="8px">
										Группа
									</Text>
									{!!value && (
										<Button
											size="xs"
											variant="ghost"
											onClick={() => {
												setValue('symbol', null);
											}}
										>
											Очистить
										</Button>
									)}
								</Flex>
								<Select
									onFocus={() => setGroupVisibility(true)}
									onBlur={() => setGroupVisibility(false)}
									showSearch
									allowClear={false}
									options={groupOption}
									value={value}
									onChange={(e) => {
										setValue('symbol', e);
									}}
									filterOption={(input, option) =>
										String(option?.label ?? '')
											.toLowerCase()
											.includes(input.toLowerCase())
									}
									optionFilterProp={'children'}
									dropdownStyle={{ zIndex: 1400 }}
									size={'large'}
									className={clsx(
										styles['select'],
										styles['select-large'],
										groupVisibility && styles['select-focus'],
										error && [styles['select-invalid']],
									)}
								/>
							</Flex>
						);
					}}
				/>
				<Controller
					name={`${type}Name`}
					control={control}
					render={({ field: { value, ...props }, fieldState: { error } }) => {
						return (
							<Flex flexDirection="column">
								<Flex flexDirection="row" justifyContent="space-between">
									<Text size="md" lineHeight="6" fontWeight="600" marginBottom="8px">
										Наименование
									</Text>
									{!!value && (
										<Button
											size="xs"
											variant="ghost"
											onClick={() => {
												setNamingOption([]);
												setValue(`${type}Name`, '');
											}}
										>
											Очистить
										</Button>
									)}
								</Flex>
								<Select
									onFocus={() => setNamingVisibility(true)}
									onBlur={() => setNamingVisibility(false)}
									showSearch
									allowClear={false}
									options={namingOption}
									searchValue={namingSearch}
									onSearch={(inputValue) => setNamingSearch(inputValue)}
									value={value}
									onChange={(e) => {
										setValue(
											`${type}Name`,
											(namingOption.find((el) => el.value == e)?.label as string) ?? e,
										);
									}}
									filterOption={(input, option) =>
										String(option?.label ?? '')
											.toLowerCase()
											.includes(input.toLowerCase())
									}
									onKeyDown={(e) => {
										debouncedNaming(e);
									}}
									optionFilterProp={'children'}
									dropdownStyle={{ zIndex: 1400 }}
									size={'large'}
									className={clsx(
										styles['select'],
										styles['select-large'],
										namingVisibility && styles['select-focus'],
										error && [styles['select-invalid']],
									)}
									notFoundContent={
										<NotFoundContentDropdown
											isFetchingNaming={isFetchingNaming}
											alertName={'Введите наименование'}
										/>
									}
								/>
							</Flex>
						);
					}}
				/>
				<Controller
					name="currentOperationsNames"
					control={control}
					render={({ field: { value, onChange, ...props }, fieldState: { error } }) => {
						getValues('currentOperationsNames');

						return (
							<Flex flexDirection="column">
								<Flex flexDirection="row" justifyContent="space-between">
									<Text size="md" lineHeight="6" fontWeight="600" marginBottom="8px">
										Текущая операция
									</Text>
									{(getValues('currentOperationsNames') || value) && (
										<Button
											size="xs"
											variant="ghost"
											onClick={() => {
												setValue('currentOperationsNames', []);
											}}
										>
											Очистить
										</Button>
									)}
								</Flex>
								<Select
									onFocus={() => setCurrOpVisibility(true)}
									onBlur={() => setCurrOpVisibility(false)}
									showSearch
									allowClear={false}
									options={currOpOption}
									value={value}
									onKeyDown={(e) => {
										// setValue('currOperation', e);
										onChange(e);
									}}
									onChange={(e) => {
										setValue('currentOperationsNames', e);
										// onChange(e);
									}}
									filterOption={(input, option) =>
										String(option?.label ?? '')
											.toLowerCase()
											.includes(input.toLowerCase())
									}
									optionFilterProp={'children'}
									dropdownStyle={{ zIndex: 1400 }}
									size={'large'}
									className={clsx(
										styles['select'],
										styles['select-large'],
										currOpVisibility && styles['select-focus'],
										error && [styles['select-invalid']],
									)}
									notFoundContent={
										<NotFoundContentDropdown
											isFetchingNaming={isCurrentOperationsLoading}
											alertName={'Введите текущую операцию'}
										/>
									}
								/>
							</Flex>
						);
					}}
				/>
				<Controller
					name={`${type}Symbol`}
					control={control}
					render={({ field: { value, ...props }, fieldState: { error } }) => {
						return (
							<Flex flexDirection="column">
								<Flex flexDirection="row" justifyContent="space-between">
									<Text size="md" lineHeight="6" fontWeight="600" marginBottom="8px">
										Обозначение
									</Text>
									{(!!labelingOption?.length || value) && (
										<Button
											size="xs"
											variant="ghost"
											onClick={() => {
												setLabelingOption([]);
												setValue(`${type}Symbol`, '');
											}}
										>
											Очистить
										</Button>
									)}
								</Flex>
								<Select
									onFocus={() => setLabelingVisibility(true)}
									onBlur={() => {
										if (!value) {
											setLabelingOption([]);
										}
										setLabelingVisibility(false);
									}}
									showSearch
									searchValue={labelingSearch}
									allowClear={false}
									options={labelingOption}
									value={value}
									onChange={(e) => {
										setValue(
											`${type}Symbol`,
											(labelingOption.find((el) => el.value === e)?.label as string) ?? e,
										); // Устанавливаем значение из списка или введенное
									}}
									filterOption={(input, option) => {
										// Фильтруем опции по введенному значению
										return String(option?.label ?? '')
											.toLowerCase()
											.includes(input.toLowerCase());
									}}
									onSearch={(inputValue) => setLabelingSearch(inputValue)}
									onKeyDown={(e) => {
										if (!value) {
											setLabelingOption([]);
										}
										debouncedLabeling(e);
									}}
									optionFilterProp={'children'}
									dropdownStyle={{ zIndex: 1400 }}
									size={'large'}
									className={clsx(
										styles['select'],
										styles['select-large'],
										labelingVisibility && styles['select-focus'],
										error && [styles['select-invalid']],
									)}
									notFoundContent={
										<NotFoundContentDropdown
											isFetchingNaming={isFetchingSymbol}
											alertName={'Введите обозначение'}
										/>
									}
								/>
							</Flex>
						);
					}}
				/>

				<Controller
					control={control}
					name="statuses"
					render={(field) => {
						return (
							<Flex flexDirection="column">
								<Flex flexDirection="row" justifyContent="space-between">
									<Text size="md" lineHeight="6" fontWeight="600" marginBottom="8px">
										Статус
									</Text>
									{!!statuses.length && (
										<Button size="xs" variant="ghost" onClick={() => setStatuses([])}>
											Очистить
										</Button>
									)}
								</Flex>
								<Flex flexDirection="column" gap="4px">
									<Checkbox
										isChecked={statuses.includes(TechCardStatuses.progress)}
										onChange={(e) => {
											onStatusChange(e.target.checked, TechCardStatuses.progress);
										}}
									>
										В работе
									</Checkbox>
									<Checkbox
										isChecked={statuses.includes(TechCardStatuses.accepted_at_sgd)}
										onChange={(e) =>
											onStatusChange(e.target.checked, TechCardStatuses.accepted_at_sgd)
										}
									>
										Принято на СГД
									</Checkbox>
									<Checkbox
										isChecked={statuses.includes(TechCardStatuses.partially_issued)}
										onChange={(e) =>
											onStatusChange(e.target.checked, TechCardStatuses.partially_issued)
										}
									>
										Частично выдана
									</Checkbox>
									<Checkbox
										isChecked={statuses.includes(TechCardStatuses.canceled)}
										onChange={(e) => onStatusChange(e.target.checked, TechCardStatuses.canceled)}
									>
										Аннулировано
									</Checkbox>
									<Checkbox
										isChecked={statuses.includes(TechCardStatuses.released)}
										onChange={(e) => onStatusChange(e.target.checked, TechCardStatuses.released)}
									>
										Отгружено
									</Checkbox>
									<Checkbox
										isChecked={statuses.includes(TechCardStatuses.completed)}
										onChange={(e) => onStatusChange(e.target.checked, TechCardStatuses.completed)}
										hidden={type === 'assembly'}
									>
										Завершено
									</Checkbox>
								</Flex>
							</Flex>
						);
					}}
				/>
				<Controller
					control={control}
					name="wasted"
					render={({ field: { value } }) => (
						<Flex flexDirection="column">
							<Flex flexDirection="row" justifyContent="space-between">
								<Text size="md" lineHeight="6" fontWeight="600" marginBottom="8px">
									Брак
								</Text>
								{value !== undefined && value !== null && (
									<Button
										size="xs"
										variant="ghost"
										onClick={() =>
											setValue('wasted', null, {
												shouldValidate: true,
											})
										}
									>
										Очистить
									</Button>
								)}
							</Flex>
							<RadioGroup
								value={value === undefined ? undefined : String(value)}
								onChange={(value) => {
									setValue('wasted', value === 'true', {
										shouldValidate: true,
									});
								}}
							>
								<Stack direction="column">
									<Radio value="true" isChecked={value === true}>
										Есть брак
									</Radio>
									<Radio value="false" isChecked={value === false}>
										Нет брака
									</Radio>
								</Stack>
							</RadioGroup>
						</Flex>
					)}
				/>
				<CalendarRangePicker
					textLabel="Дата создания"
					setDates={selectCreatedDates}
					defaultValue={{
						from: defaultValue?.createdAtFrom,
						to: defaultValue?.createdAtTo,
					}}
				/>
				<CalendarRangePicker
					textLabel="Дата принятия на СГД"
					setDates={selectSGDDates}
					defaultValue={{
						from: defaultValue?.storageAtFrom,
						to: defaultValue?.storageAtTo,
					}}
				/>
			</form>
		</SideModal>
	);
};
