export enum TechCardStatuses {
	released = 'released',
	accepted_at_sgd = 'accepted_at_sgd',
	progress = 'progress',
	canceled = 'canceled',
	partially_issued = 'partially_issued',
	completed = 'completed',
}
export enum UserRoles {
	user = 'user',
	operator = 'operator',
	admin = 'admin',
	senior_operator = 'senior_operator',
}

export type StandardConsumptionTypeEnum = 'meters' | 'square_meters' | 'grams' | 'kg' | 'old';

export type TechCardStatus = `${TechCardStatuses}`;

export type UserRole = `${UserRoles}`;
