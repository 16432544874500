import { Table, Tr, Th, Td, Flex, Input, Thead, Tfoot, Button } from '@chakra-ui-kraud/react';
import { FC, useEffect, useRef, useState } from 'react';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import styles from './SGD.module.scss';
import { TechCardStatuses } from '@/shared/core';
import { MadIcon } from 'madsoft-icons';
import { useTechCard } from '@/shared';
import { AssemblyConfig } from '../../form-assembly-config';
import { Controller, useFormContext } from 'react-hook-form';
import { AssemblySgdOutResponse } from '@/shared/state/api/swagger';
import type { SGDAssemblyBlockTypes } from './SGD.types';
import defaultStyles from '../../tech-card.module.scss';
import { clsx } from 'clsx';

export const SGDAssemblyBlock: FC<SGDAssemblyBlockTypes> = ({
	isEditable,
	employee,
	isPrinting,
	focusedBlock,
	setFocusedBlock,
}) => {
	const { assemblyCard: cardInfo } = useTechCard();

	const { control, setValue, getValues } = useFormContext<AssemblyConfig>();

	const [rows, setRows] = useState(3);

	const { assembly_sgd_out: fields } = getValues();

	const emptyRows = (length: number) => {
		const rows = new Array(length ?? 3).fill({});
		return rows.map((_, idx) => (
			<Tr key={idx}>
				<Td>&nbsp;</Td>
				<Td>&nbsp;</Td>
				<Td>&nbsp;</Td>
				<Td>&nbsp;</Td>
				<Td>&nbsp;</Td>
				<Td>&nbsp;</Td>
			</Tr>
		));
	};

	const header = useRef<HTMLTableSectionElement>(null);

	useEffect(() => {
		if (isPrinting && cardInfo?.assembly_operations) {
			// Число недостоящих строк "Выдано из СГД" для заполнения до конца второй страницы при печати
			// 64 - число строк, которые помещаются на двух страницах после первого сегмента
			// 2 - число пустых строк после кастомных операций
			const rowsToPrint =
				64 - (cardInfo?.assembly_operations.length + 2 + (cardInfo?.assembly_sgd_out?.length ?? 0));
			setRows(rowsToPrint);
		} else {
			setRows(3);
		}
	}, [cardInfo?.assembly_operations, cardInfo?.assembly_sgd_out?.length, isPrinting]);

	return (
		<Flex direction="column" gap={'10px'} className={styles['sgd']}>
			<>
				<Flex
					direction={'column'}
					onFocus={() => setFocusedBlock && setFocusedBlock('SGDOut')}
					className={clsx(
						focusedBlock?.block === 'SGDOut' &&
							focusedBlock.focusedBy === 'tab' &&
							!focusedBlock.atCurrentBlock &&
							defaultStyles.focused,
					)}
				>
					<Table
						style={{ tableLayout: 'fixed' }}
						className={
							isEditable && cardInfo?.status !== TechCardStatuses.progress ? styles['edit-table'] : ''
						}
					>
						<Thead ref={header} className={styles['sgd__thead']}>
							<Tr>
								<Th colSpan={6} style={{ verticalAlign: 'middle' }}>
									Выдано из СГД
								</Th>
							</Tr>
							<Tr>
								<Th style={{ verticalAlign: 'middle' }}>Дата упаковки и консервации</Th>
								<Th style={{ verticalAlign: 'middle' }}>Кол-во</Th>
								<Th style={{ verticalAlign: 'middle' }}>Получатель</Th>
								<Th style={{ verticalAlign: 'middle' }}>Спецификация</Th>
								<Th style={{ verticalAlign: 'middle' }}>Подпись</Th>
								<Th style={{ verticalAlign: 'middle', width: '225px' }}>ФИО</Th>
							</Tr>
						</Thead>

						<>
							{!isEditable && (
								<>
									{Boolean(cardInfo?.assembly_sgd_out?.length) && (
										<>
											{cardInfo?.assembly_sgd_out?.map((item, idx) => (
												<Tr key={idx}>
													<Td>
														{item.date && dayjs(item.date).isValid()
															? dayjs(item.date).format('DD.MM.YY')
															: ''}
													</Td>
													<Td>{item.count}</Td>
													<Td>{item.receiver}</Td>
													<Td>{item.certificate}</Td>
													<Td></Td>
													<Td>{item.fio}</Td>
												</Tr>
											))}
										</>
									)}
									{isPrinting ? emptyRows(rows) : !cardInfo?.assembly_sgd_out?.length && emptyRows(3)}{' '}
								</>
							)}
							{isEditable ? (
								cardInfo?.status === TechCardStatuses.progress ? (
									emptyRows(rows)
								) : (
									<>
										{fields?.map((field, index) => {
											return (
												<Tr key={field.id}>
													<Td>
														<Controller
															name={`assembly_sgd_out.${index}.date`}
															control={control}
															render={({
																field: { onChange },
																fieldState: { error },
															}) => (
																<DatePicker
																	tabIndex={10}
																	status={error ? 'error' : ''}
																	onChange={onChange}
																	defaultValue={
																		field.date && dayjs(field.date).isValid()
																			? dayjs(field.date)
																			: undefined
																	}
																	format={'DD.MM.YY'}
																	name={`assembly_sgd_out.${index}.date`}
																	autoFocus={
																		(focusedBlock?.block ===
																			'select_date_conservation' ||
																			focusedBlock?.block === 'SGDOut') &&
																		index === 0
																			? true
																			: false
																	}
																	style={{ width: '100%' }}
																/>
															)}
														/>
													</Td>
													<Td>
														<Controller
															name={`assembly_sgd_out.${index}.count`}
															control={control}
															render={({
																field: { onChange, value },
																fieldState: { error },
															}) => (
																<>
																	<Input
																		tabIndex={10}
																		isInvalid={!!error}
																		onChange={onChange}
																		value={value}
																		name={`assembly_sgd_out.${index}.count`}
																		backgroundColor="transparent"
																	/>
																</>
															)}
														/>
													</Td>
													<Td>
														<Controller
															name={`assembly_sgd_out.${index}.receiver`}
															control={control}
															render={({
																field: { onChange, value },
																fieldState: { error },
															}) => (
																<>
																	<Input
																		tabIndex={10}
																		isInvalid={!!error}
																		onChange={onChange}
																		value={value}
																		name={`assembly_sgd_out.${index}.receiver`}
																		backgroundColor="transparent"
																	/>
																</>
															)}
														/>
													</Td>
													<Td>
														<Controller
															name={`assembly_sgd_out.${index}.certificate`}
															control={control}
															render={({
																field: { onChange, value },
																fieldState: { error },
															}) => (
																<>
																	<Input
																		tabIndex={10}
																		isInvalid={!!error}
																		onChange={onChange}
																		value={value}
																		name={`assembly_sgd_out.${index}.certificate`}
																		backgroundColor="transparent"
																	/>
																</>
															)}
														/>
													</Td>

													<Td></Td>
													<Td>
														<Controller
															name={`assembly_sgd_out.${index}.fio`}
															control={control}
															render={({ field: { value, onChange } }) => (
																<Select
																	allowClear
																	tabIndex={10}
																	filterOption={(input, option) =>
																		(option?.label ?? '')
																			.toLowerCase()
																			.includes(input.toLowerCase())
																	}
																	showSearch
																	placeholder="Выберите ФИО"
																	optionFilterProp="children"
																	onChange={(e) => {
																		onChange(e);
																		setValue(
																			`assembly_sgd_out.${index}.fio`,
																			e
																				? employee.find(
																						(el) => el.value === Number(e),
																				  )?.label
																				: '',
																		);
																	}}
																	value={value}
																	options={employee}
																	style={{ width: '100%' }}
																	className={styles['custom-select']}
																/>
															)}
														/>
													</Td>
												</Tr>
											);
										})}
									</>
								)
							) : null}
						</>
						<Tfoot style={{ display: 'table-footer-group' }}>
							<Tr height="20px"></Tr>
						</Tfoot>
					</Table>
				</Flex>
				{isEditable && (
					<Button
						minW="1840px"
						size="sm"
						variant="ghost"
						colorScheme="primary"
						rightIcon={<MadIcon module="basic" type="outline" mode="default" size="default" name="plus" />}
						onClick={() => {
							setRows((prev) => ++prev);
							setValue('assembly_sgd_out', [...(fields || []), {} as AssemblySgdOutResponse]);
						}}
					>
						Добавить строку
					</Button>
				)}
			</>
		</Flex>
	);
};
