import {
	IssueReportResponse,
	IssueReportSortingFields,
	IssueReportStatusEnum,
	UserResponse,
} from '@/shared/state/api/swagger';
import { ColumnsType } from 'antd/es/table';
import { SortOrder } from 'antd/es/table/interface';
import { Link } from 'react-router-dom';
import { LINK_TO_TECH_CARD_INFO, CellTableParagraph, abbreviateUser } from '@/shared';
import { IssueColumnId, IssueStatusColumn } from './cells';
import { IssueActionColumn } from './action-column';

import dayjs from 'dayjs';
import styles from './issues-table.module.scss';
import { differenceInDays } from '@/shared/core/utils/days-difference';
import { getTechCardName } from '@/shared/core/utils/tech-card-utils';

const sortDirections = ['ascend', 'descend'] as keyof SortOrder;

const orderBy: Record<IssueReportSortingFields, IssueReportSortingFields> = {
	open_at: 'open_at',
	operation_name: 'operation_name',
	product_name: 'product_name',
	status: 'status',
	responsible_user: 'responsible_user',
	stuck_operation_at: 'stuck_operation_at',
	executor_user: 'executor_user',
};

const STUB = '—';

export const ISSUES_TABLE_COLUMNS: ColumnsType<IssueReportResponse> = [
	{
		key: 'id',
		dataIndex: 'id',
		title: 'ID проблемы',
		fixed: true,
		align: 'left',
		sortDirections,
		className: styles['table__id'],
		render: (_, row) => <IssueColumnId {...row} />,
	},
	{
		key: orderBy['open_at'],
		dataIndex: orderBy['open_at'],
		title: 'Обнаружена',
		sorter: true,
		sortDirections,
		className: styles['table__opened-at'],
		render: (value) => (
			<CellTableParagraph
				value={dayjs(value).isValid() ? dayjs(value).format('DD.MM.YYYY') : undefined}
				textAlign={'right'}
			/>
		),
	},
	{
		key: 'stuck_operation_at',
		dataIndex: 'stuck_operation_at',
		title: 'Дней простоя',
		sortDirections,
		sorter: true,
		className: styles['table__stuck-operation-at'],
		render: (value) => {
			return <CellTableParagraph value={differenceInDays(value)} textAlign="left" />;
		},
	},
	{
		key: orderBy['status'],
		dataIndex: orderBy['status'],
		title: 'Статус',
		sorter: true,
		sortDirections,
		className: styles['table__status'],
		render: (value: IssueReportStatusEnum) => <IssueStatusColumn status={value} />,
	},
	{
		key: 'product_tech_map_number',
		dataIndex: ['product_tech_map'],
		className: styles['table__tech-map'],
		title: 'Маршрутная карта',
		render: (value) => (
			<Link to={LINK_TO_TECH_CARD_INFO(value.number)} className={styles['link']} target="_blank">
				<CellTableParagraph value={getTechCardName(value)} />
			</Link>
		),
	},
	{
		key: 'symbol',
		dataIndex: ['product_tech_map', 'product', 'symbol'],
		className: styles['symbol'],
		title: 'Обозначение',
		render: (value) => <CellTableParagraph value={value} />,
	},
	{
		key: orderBy['product_name'],
		dataIndex: ['product_tech_map', 'product', 'name'],
		title: 'Наименование детали',
		sorter: true,
		sortDirections,
		className: styles['table__product'],
		render: (value) => <CellTableParagraph value={value} />,
	},
	{
		key: orderBy['responsible_user'],
		dataIndex: orderBy['responsible_user'],
		title: 'Ответственный',
		sorter: true,
		className: styles['table__responsible'],
		sortDirections,
		render: (value: UserResponse) => (
			<CellTableParagraph
				value={value ? `${value?.first_name} ${value?.middle_name} ${value?.last_name}` : STUB}
			/>
		),
	},
	{
		key: orderBy['operation_name'],
		dataIndex: ['problem_operation', 'name'],
		title: 'Операция в МК',
		sorter: true,
		className: styles['table__operation'],
		sortDirections,
		render: (value) => <CellTableParagraph value={value ?? STUB} />,
	},

	{
		key: orderBy['executor_user'],
		dataIndex: ['executor_user'],
		title: 'Исполнитель',
		sorter: true,
		className: styles['table__executor'],
		sortDirections,
		render: (value: UserResponse) => (
			<CellTableParagraph
				value={
					value
						? abbreviateUser(value.first_name ?? '', value.last_name ?? '', value.middle_name ?? '')
						: STUB
				}
			/>
		),
	},
	{
		key: 'actions',
		dataIndex: 'status',
		className: styles['table__actions'],
		align: 'left',
		fixed: 'right',
		title: 'Действия',
		render: (_, row) => <IssueActionColumn {...row} />,
	},
];
