import { FC } from 'react';
import { IssueDetailsProps } from '../model/issue-details.types';
import { chakra, Stack, Text } from '@chakra-ui-kraud/react';
import dayjs from 'dayjs';
import { IssueBadge } from '@/entities/issue';
import { useGetIssueStatusMappingQuery, useGetIssueTypeMappingQuery } from '@/shared/state/api/swagger';
import { Link } from 'react-router-dom';
import { LINK_TO_TECH_CARD_INFO, LabeledBlock } from '@/shared';

import styles from './issue-details.module.scss';

export const SolveIssueDetails: FC<IssueDetailsProps> = ({
	open_at,
	status,
	type,
	product_tech_map,
	stuckOperation,
}) => {
	const { data: statusLocaleMap = {} } = useGetIssueStatusMappingQuery();
	const { data: typeLocaleMap = {} } = useGetIssueTypeMappingQuery();

	return (
		<Stack gap={3}>
			<LabeledBlock
				label={<Text className={styles['block__label']}>Тип проблемы</Text>}
				content={
					<Text className={styles['block__field']}>
						{typeLocaleMap && type && (typeLocaleMap as any)[type]}
					</Text>
				}
			/>
			<LabeledBlock
				label={<Text className={styles['block__label']}>Дата создания</Text>}
				content={
					<Text className={styles['block__field']}>{open_at && dayjs(open_at).format('DD.MM.YYYY')}</Text>
				}
			/>
			<LabeledBlock
				label={<Text className={styles['block__label']}>Статус</Text>}
				content={
					<IssueBadge value={status}>
						{/*TODO remove any after fixes from backend */}
						<chakra.p>{status && (statusLocaleMap as any)[status]}</chakra.p>
					</IssueBadge>
				}
			/>
			<LabeledBlock
				label={<Text className={styles['block__label']}>Маршрутная карта</Text>}
				content={
					product_tech_map?.number && (
						<Link
							to={LINK_TO_TECH_CARD_INFO(product_tech_map?.number)}
							className={styles['link']}
							target="_blank"
						>
							<chakra.p>{product_tech_map?.number}</chakra.p>
						</Link>
					)
				}
			/>
			<LabeledBlock
				label={<Text className={styles['block__label']}>Наименование детали</Text>}
				content={<Text className={styles['block__field']}>{product_tech_map?.product?.name}</Text>}
			/>
			{type === 'stuck_operation' && stuckOperation && (
				<LabeledBlock
					label={<Text className={styles['block__label']}>Текущее состояние</Text>}
					content={
						<Text className={styles['block__field']}>
							Находится на операции {stuckOperation?.name} с{' '}
							{dayjs(stuckOperation?.created_at).format('DD.MM.YYYY')}
						</Text>
					}
				/>
			)}
		</Stack>
	);
};
