// определение завершенной операции (если есть ФИО и дата)

import { ProductOperationDomain } from '@/shared/state/api/swagger';

// определение завершенной операции (если есть ФИО и дата) и пустого поля
export const isOperationDoneAndEmptyField = (
	operation: ProductOperationDomain,
	fieldValue: string | number | null | undefined,
) => {
	if (!!operation.fio?.length && !!operation.date?.length && !fieldValue) {
		return true;
	}
	return false;
};
// определение завершенной операции (если есть ФИО и дата)
export const isOperationDone = (operation: Pick<ProductOperationDomain, 'fio' | 'date'>) => {
	if (!!operation.fio?.length && !!operation.date?.length) {
		return true;
	}
	return false;
};
