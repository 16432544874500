import type { FC } from 'react';
import type { TechCardActionsProps } from './tech-card-actions.types';

import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	Button,
	ButtonGroup,
	IconButton,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Portal,
	Tooltip,
	useToast,
} from '@chakra-ui-kraud/react';
import { MadIcon } from 'madsoft-icons';

import { LINK_TO_TECH_CARD_ASSEMBLY_EDIT, LINK_TO_TECH_CARD_EDIT } from '@/shared/core';
import { TechCardStatuses, UserRoles } from '@/shared/core';
import { useAppSelector } from '@/shared/state';
import { CanceledStatusModal } from '@/entities/tables/tech-card';
import {
	generatedApi,
	ProductTechMapResponse,
	useCancelAssemblyTechMapMutation,
	useCancelTechmapMutation,
	useDeleteAssemblyTechMapMutation,
	useDeleteTechmapMutation,
} from '@/shared/state/api/swagger';

import { showErrorToast, showSuccessToast } from '../../shared/components/toasts';

import { DeleteModal } from '@/shared';

import styles from './tech-card-actions.module.scss';

export const TechCardActions: FC<TechCardActionsProps> = ({
	status,
	value,
	onExcel,
	type,
	activeMenuItem,
	setActiveMenuItem,
	itemId,
}) => {
	const navigate = useNavigate();
	const toast = useToast();
	const dispatch = useDispatch();

	const userRole = useAppSelector((state) => state.auth.userProfile?.role);

	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const [isResetMode, setIsResetMode] = useState(false);

	const [cancelTechMap] = useCancelTechmapMutation();
	const [deleteTechMap] = useDeleteTechmapMutation();
	const [cancelAssemblyTechMapMutation] = useCancelAssemblyTechMapMutation();
	const [deleteAssemblyTechMap] = useDeleteAssemblyTechMapMutation();

	const handleDeleteAssemblyTechCard = useCallback(
		(assemblyTechMapNumber: number) => {
			deleteAssemblyTechMap({ assemblyTechMapId: assemblyTechMapNumber })
				.unwrap()
				.then(() => {
					showSuccessToast(toast, { description: `МК № ${value?.number} удалена` });
				})
				.catch(() => {
					showErrorToast(toast, { description: 'При удалении МК произошла ошибка' });
				});
			setDeleteModalOpen(false);
		},
		[deleteAssemblyTechMap, navigate, toast, value?.number],
	);

	const cancelAssemblyTechMap = useCallback(() => {
		cancelAssemblyTechMapMutation({ assemblyTechMapId: Number(value?.id) })
			.unwrap()
			.then(() => {
				showSuccessToast(toast, { description: `Карта № ${value.number} аннулирована` });
			})
			.catch(() => showErrorToast(toast, { description: 'При аннулировании МК произошла ошибка' }));
	}, [cancelAssemblyTechMapMutation, value?.id, value.number, toast, dispatch]);

	const handleDeleteTechCard = (productTechMapNumber: number) => {
		deleteTechMap({ productTechMapNumber })
			.unwrap()
			.then(() => {
				showSuccessToast(toast, { description: `МК № ${productTechMapNumber} удалена` });
				// @ts-ignore TODO: https://github.com/reduxjs/redux-toolkit/issues/1510
				dispatch(generatedApi.util.invalidateTags(['Product_tech_map endpoints']));
			})
			.catch(() => {
				showErrorToast(toast, { description: 'При удалении МК произошла ошибка' });
			});
		setDeleteModalOpen(false);
	};

	const changeStatusToCanceled = () => {
		value.id &&
			cancelTechMap({
				productTechMapChangeStatusAdminRequest: {
					status: 'canceled',
					product_tech_map_id: value.id,
				},
			})
				.unwrap()
				.then(() => {
					// @ts-ignore TODO: https://github.com/reduxjs/redux-toolkit/issues/1510
					dispatch(generatedApi.util.invalidateTags(['Product_tech_map endpoints']));
					showSuccessToast(toast, { description: `Карта №${value.number} аннулирована` });
				})
				.catch(() => showErrorToast(toast, { description: 'При аннулировании МК произошла ошибка' }));

		setIsResetMode(false);
	};

	return (
		<>
			<ButtonGroup className={styles['menu']}>
				<Tooltip label="Редактировать МК" hasArrow placement="bottom">
					<IconButton
						aria-label=""
						icon={
							<MadIcon module="basic" name="pencil-square" type="outline" mode="default" size="default" />
						}
						size="xs"
						fontSize={'14px'}
						variant="ghost"
						colorScheme="tertiary"
						onClick={() =>
							type === 'assembly'
								? value.number && navigate(LINK_TO_TECH_CARD_ASSEMBLY_EDIT(value.number))
								: value.number && navigate(LINK_TO_TECH_CARD_EDIT(value.number))
						}
						className={styles['menu-icon']}
						_focus={{ boxShadow: 'none' }}
					/>
				</Tooltip>
				{userRole !== UserRoles.user && (
					<>
						<Menu isOpen={activeMenuItem === itemId} closeOnBlur closeOnSelect>
							<MenuButton
								as={IconButton}
								aria-label=""
								icon={
									<MadIcon
										module="basic"
										type="outline"
										mode="default"
										size="default"
										name="ellipsis-vertical"
									/>
								}
								className={styles['menu-button']}
								size="xs"
								variant="unstyled"
								colorScheme="tertiary"
								onClick={() => setActiveMenuItem(itemId)}
								style={{ marginInlineStart: '0', WebkitMarginStart: '0' }}
								_focus={{ boxShadow: 'none' }}
							/>
							<Portal>
								<MenuList
									p="12px"
									borderRadius="12px"
									border="none"
									minW="none"
									zIndex={1500}
									onMouseLeave={() => setActiveMenuItem(null)}
									onMouseUp={() => setActiveMenuItem(null)}
								>
									{(status === TechCardStatuses.accepted_at_sgd ||
										status === TechCardStatuses.partially_issued) && (
										<>
											<MenuItem
												as={Button}
												size="sm"
												variant="ghost"
												fontFamily="Raleway"
												colorScheme="tertiary"
												justifyContent="flex-start"
												onClick={() =>
													value.number && type === 'product'
														? navigate(LINK_TO_TECH_CARD_EDIT(value.number), {
																state: { focusedBlock: 'select_date_conservation' },
														  })
														: navigate(LINK_TO_TECH_CARD_ASSEMBLY_EDIT(value.number!), {
																state: { focusedBlock: 'select_date_conservation' },
														  })
												}
												_focus={{ boxShadow: 'none' }}
											>
												Выдать...
											</MenuItem>
											<MenuDivider opacity="0.1" />
										</>
									)}

									{(userRole === UserRoles.admin || userRole === UserRoles.senior_operator) &&
										status !== TechCardStatuses.canceled && (
											<>
												<MenuItem
													as={Button}
													size="sm"
													variant="ghost"
													fontFamily="Raleway"
													colorScheme="tertiary"
													justifyContent="flex-start"
													onClick={() => setIsResetMode(true)}
													_focus={{ boxShadow: 'none' }}
												>
													Аннулировать
												</MenuItem>
											</>
										)}
									{userRole === UserRoles.admin && (
										<>
											<MenuItem
												as={Button}
												size="sm"
												variant="ghost"
												fontFamily="Raleway"
												colorScheme="danger"
												justifyContent="flex-start"
												color="red"
												onClick={() => setDeleteModalOpen(true)}
											>
												Удалить карту
											</MenuItem>
											<MenuDivider hidden={type === 'assembly'} opacity="0.1" />
										</>
									)}

									<MenuItem
										as={Button}
										size="sm"
										hidden={type === 'assembly'}
										variant="ghost"
										fontFamily="Raleway"
										colorScheme="tertiary"
										justifyContent="flex-start"
										onClick={() => onExcel(value as ProductTechMapResponse)}
										_focus={{ boxShadow: 'none' }}
									>
										Скачать в Excel
									</MenuItem>
								</MenuList>
							</Portal>
						</Menu>
					</>
				)}
			</ButtonGroup>
			<Portal>
				<CanceledStatusModal
					isOpen={isResetMode}
					onClose={() => setIsResetMode(false)}
					onCancelTechMap={type === 'product' ? changeStatusToCanceled : cancelAssemblyTechMap}
				/>
			</Portal>
			<Portal>
				<DeleteModal
					title="Удаление МК"
					text="Вы уверены, что хотите удалить МК?"
					isOpen={deleteModalOpen}
					onClose={() => setDeleteModalOpen(false)}
					onDelete={() =>
						type === 'product'
							? handleDeleteTechCard(Number(value?.number))
							: handleDeleteAssemblyTechCard(Number(value?.id))
					}
				/>
			</Portal>
		</>
	);
};
