import * as generated from './core-api.generated';
import queryString from 'query-string';
import {
	GetAssemblyTechMapsApiArg,
	GetAssemblyTechMapsApiResponse,
	GetAssemblyTechMapsExcelApiArg,
	GetAssemblyTechMapsExcelApiResponse,
	GetTechMapsApiResponse,
} from './core-api.generated';
import { OverridedGetTechMapsApiArg } from './override.types';

generated.coreApi.injectEndpoints({
	endpoints: (build) => ({
		getTechMaps: build.query<GetTechMapsApiResponse, OverridedGetTechMapsApiArg>({
			query: (queryArg) => {
				const statusesParams = queryString.stringify({ statuses: queryArg.statuses });
				const statuses = queryArg.statuses ? `?${statusesParams}` : '';

				return {
					url: `/product_tech_map${statuses}`,
					params: {
						string_to_search: queryArg.stringToSearch,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						id: queryArg.id,
						number: queryArg['number'],
						symbol: queryArg['symbol'],
						product_name: queryArg.productName,
						product_symbol: queryArg.productSymbol,
						wasted: queryArg.wasted,
						count_number_from: queryArg.countNumberFrom,
						count_number_to: queryArg.countNumberTo,
						created_at_from: queryArg.createdAtFrom,
						created_at_to: queryArg.createdAtTo,
						storage_at_from: queryArg.storageAtFrom,
						storage_at_to: queryArg.storageAtTo,
						current_operations_names: queryArg.currentOperationsNames,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
						product_name_strict: queryArg.productNameStrict,
						product_symbol_strict: queryArg.productSymbolStrict,
					},
				};
			},
			providesTags: ['Product_tech_map endpoints'],
		}),
		getAssemblyTechMaps: build.query<GetAssemblyTechMapsApiResponse, GetAssemblyTechMapsApiArg>({
			query: (queryArg) => {
				const statusesParams = queryString.stringify({ statuses: queryArg.statuses });
				const statuses = queryArg.statuses ? `?${statusesParams}` : '';

				return {
					url: `/assembly_tech_map${statuses}`,
					params: {
						string_to_search: queryArg.stringToSearch,
						id: queryArg.id,
						number: queryArg['number'],
						symbol: queryArg['symbol'],
						assembly_name_strict: queryArg.assemblyNameStrict,
						assembly_symbol_strict: queryArg.assemblySymbolStrict,
						assembly_name: queryArg.assemblyName,
						assembly_symbol: queryArg.assemblySymbol,
						wasted: queryArg.wasted,
						accepted_at_sgd_count_from: queryArg.acceptedAtSgdCountFrom,
						accepted_at_sgd_count_to: queryArg.acceptedAtSgdCountTo,
						created_at_from: queryArg.createdAtFrom,
						created_at_to: queryArg.createdAtTo,
						storage_at_from: queryArg.storageAtFrom,
						storage_at_to: queryArg.storageAtTo,
						current_operations_names: queryArg.currentOperationsNames,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				};
			},
			providesTags: ['Assembly tech map endpoints'],
		}),
		getIssueListExcel: build.mutation<generated.GetIssueListExcelApiResponse, generated.GetIssueListExcelApiArg>({
			query: (queryArg) => ({
				url: '/issue/excel/list/',
				method: 'GET',
				params: {
					string_to_search: queryArg.stringToSearch,
					type: queryArg['type'],
					status: queryArg.status,
					executor_user_id: queryArg.executorUserId,
					responsible_user_id: queryArg.responsibleUserId,
					product_symbol: queryArg.productSymbol,
					open_at_from: queryArg.openAtFrom,
					open_at_to: queryArg.openAtTo,
					order_by: queryArg.orderBy,
					order_dir: queryArg.orderDir,
				},
				responseHandler(response) {
					return response.blob();
				},
			}),
			invalidatesTags: ['Issue endpoints'],
		}),
		getNumCatalogListExcel: build.mutation<
			generated.GetNumCatalogListExcelApiResponse,
			generated.GetNumCatalogListExcelApiArg
		>({
			query: (queryArg) => ({
				url: '/num_catalog/excel/list/',
				method: 'GET',
				params: {
					string_to_search: queryArg.stringToSearch,
					order_by: queryArg.orderBy,
					order_dir: queryArg.orderDir,
				},
				responseHandler(response) {
					return response.blob();
				},
			}),
			invalidatesTags: ['Num_catalog endpoints'],
		}),
		getTechMapExcel: build.mutation<generated.GetTechMapExcelApiResponse, generated.GetTechMapExcelApiArg>({
			query: (queryArg) => ({
				url: `/product_tech_map/get_excel/${queryArg.productTechMapNumber}`,
				method: 'GET',
				responseHandler(response) {
					return response.blob();
				},
			}),
			invalidatesTags: ['Product_tech_map endpoints'],
		}),
		getTechMapListExcel: build.mutation<
			generated.GetTechMapListExcelApiResponse,
			generated.GetTechMapListExcelApiArg
		>({
			query: (queryArg) => ({
				url: '/product_tech_map/get_excel/list/',
				method: 'GET',
				params: {
					string_to_search: queryArg.stringToSearch,
					order_by: queryArg.orderBy,
					order_dir: queryArg.orderDir,
					id: queryArg.id,
					number: queryArg['number'],
					symbol: queryArg['symbol'],
					product_name: queryArg.productName,
					product_symbol: queryArg.productSymbol,
					statuses: queryArg.statuses,
					wasted: queryArg.wasted,
					count_number_from: queryArg.countNumberFrom,
					count_number_to: queryArg.countNumberTo,
					created_at_from: queryArg.createdAtFrom,
					created_at_to: queryArg.createdAtTo,
					storage_at_from: queryArg.storageAtFrom,
					storage_at_to: queryArg.storageAtTo,
					current_operations_names: queryArg.currentOperationsNames,
				},
				responseHandler(response) {
					return response.blob();
				},
			}),
			invalidatesTags: ['Product_tech_map endpoints'],
		}),
		getAssemblyTechMapsExcel: build.query<GetAssemblyTechMapsExcelApiResponse, GetAssemblyTechMapsExcelApiArg>({
			query: (queryArg) => ({
				url: `/assembly_tech_map/excel`,
				params: {
					string_to_search: queryArg.stringToSearch,
					id: queryArg.id,
					number: queryArg['number'],
					symbol: queryArg['symbol'],
					assembly_name_strict: queryArg.assemblyNameStrict,
					assembly_symbol_strict: queryArg.assemblySymbolStrict,
					assembly_name: queryArg.assemblyName,
					assembly_symbol: queryArg.assemblySymbol,
					statuses: queryArg.statuses,
					wasted: queryArg.wasted,
					accepted_at_sgd_count_from: queryArg.acceptedAtSgdCountFrom,
					accepted_at_sgd_count_to: queryArg.acceptedAtSgdCountTo,
					created_at_from: queryArg.createdAtFrom,
					created_at_to: queryArg.createdAtTo,
					storage_at_from: queryArg.storageAtFrom,
					storage_at_to: queryArg.storageAtTo,
					current_operations_names: queryArg.currentOperationsNames,
					order_by: queryArg.orderBy,
					order_dir: queryArg.orderDir,
				},
				responseHandler(response) {
					return response.blob();
				},
			}),
			providesTags: ['Assembly tech map endpoints'],
		}),
		addFileToDetail: build.mutation<Blob, generated.AddFileToDetailApiArg>({
			query: (queryArg) => ({
				url: `/product_catalog/${queryArg.id}/file`,
				method: 'POST',
				body: (() => {
					const fd = new FormData();
					Object.entries(queryArg.bodyAddFileToDetail).forEach(([key, value]) => fd.append(key, value));

					return fd;
				})(),
			}),
			invalidatesTags: ['Product_catalog endpoints'],
		}),
		changeTechMapFile: build.mutation<Blob, generated.ChangeTechMapFileApiArg>({
			query: (queryArg) => ({
				url: `/product_tech_map/${queryArg.id}/file`,
				method: 'PUT',
				body: (() => {
					const fd = new FormData();
					Object.entries(queryArg.bodyChangeTechMapFile).forEach(([key, value]) => fd.append(key, value));

					return fd;
				})(),
			}),
			invalidatesTags: ['Product_tech_map endpoints'],
		}),
		getFile: build.mutation<Blob, generated.GetFileApiArg>({
			query: (queryArg) => ({
				url: `/file/${queryArg.id}`,
				method: 'GET',
				responseHandler(response) {
					return response.blob();
				},
			}),
			invalidatesTags: ['File endpoints'],
		}),
	}),
	overrideExisting: true,
});

export * from './core-api.generated';
